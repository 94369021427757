import React from 'react'
import { useTranslation } from 'react-i18next'

const NoFilterResults = () => {
  const { t } = useTranslation()
  return (
    <div className='text-black mb-32'>
      <ul className="list-disc ml-7 mt-4 md:mt-2 text-lg md:text-xl">
        <li>{t('filter.tryAdjustingFilters')}</li>
      </ul>
    </div>
  )
}

export default NoFilterResults
