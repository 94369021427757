export const normalizeUrl = (experience, defaultLang) => {
  return `/${defaultLang}/${experience?.city?.name}/${
    experience?.urlSlug || experience?.name
  }/${experience?.id}`
    .replace(/\s/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export const normalizeItem = (item) => {
  return `/${item?.type}/${item?.name}/${item?.id}`
    .replace(/\s/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export const normalizeFilter = (searchFilters) => {
  function objectToQueryParams(obj) {
    return Object.entries(obj)
      .filter(([key, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]:any) => {
        if (Array.isArray(value)) {
          return value
            .map((item) => `${key}=${encodeURIComponent(item)}`)
            .join('&')
        } else {
          return `${key}=${encodeURIComponent(value)}`
        }
      })
      .join('&')
  }
  let queryParams = objectToQueryParams(searchFilters)
  queryParams = queryParams.replace(/&+/g, '&').replace(/^&/, '')
  return `${queryParams}`
    .replace(/\s/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const getCountryDestinationUrl = (country) => {
  return country
    ? `/destinations/country/${country?.name}/${country?.id}`
        .replace(/\s/g, '-')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : '/destinations'
}
