import { GeneralCarousel } from '@/components/Carousels/GeneralCarousel'
import { ExperienceList } from '@/components/ExperienceList'
import { Layout } from '@/components/Layout'
import { SearchBar } from '@/components/SearchBar'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { Button } from '@nextui-org/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { NoResults } from '@/components/NoResults'
import { ExperienceType } from '@/constants/generalCarousel'
import { useIsMobile } from '@/utils/mobile'
import { Loading } from '@/components/Loading'
import { FilterButton } from '@/components/FilterButton'
import { getFilterParams } from '@/utils/getFilterParams'
import { track } from '@/utils/analytics'

const SearchPage = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const {
    hooks: {
      getFilterExperiences,
      getCities,
      setFilterData,
      setFavoriteExperience,
    },
    state: {
      cities,
      loadingExperiences,
      hasSearchResults,
      experiences,
      pagination,
      user,
    },
  } = useGotuuriContext()

  const [currentPage, setCurrentPage] = useState(pagination?.currentPage ?? 1)
  const { search } = useLocation()
  const navigate = useNavigate()

  const SearchBarCallBack = ({ filterUrl }) => {
    navigate(`/search?${filterUrl}`)
  }
  const params = useMemo(() => new URLSearchParams(search), [search])
  const { urlParams, filtersCount } = getFilterParams(params)

  useEffect(() => {
    setFilterData({
      urlParams: urlParams,
      filtersCount: filtersCount,
    })
    setCurrentPage(1)
    loadExperiences({
      pageNumber: 1,
      newSearch: true,
    })
  }, [params])

  useEffect(() => {
    if (!!!cities) getCities()
  }, [])

  const loadExperiences = async ({ newSearch, pageNumber }) => {
    if (pageNumber <= (pagination?.totalPage || 1)) {
      const result = await getFilterExperiences({
        page: pageNumber,
        data: { ...urlParams },
        newSearch: newSearch,
      })
      if (
        Object.keys(urlParams).length === 1 &&
        urlParams.hasOwnProperty('searchText') &&
        result
      ) {
        track('Search Tracking', {
          searchTerm: urlParams?.searchText,
          results: result?.pagination?.total_records,
        })
      }
    }
  }

  const loadMoreExperiences = useCallback(
    (pageNumber) => {
      if (!!pagination?.currentPage && pageNumber <= pagination?.totalPage) {
        setCurrentPage(pageNumber)
        getFilterExperiences({
          page: pageNumber,
          data: { ...urlParams },
          newSearch: false,
        })
      }
    },
    [experiences]
  )

  return (
    <Layout>
      {loadingExperiences ? (
        <Loading />
      ) : (
        <div className="flex flex-col md:mt-12">
          <div className="w-full md:w-10/12 my-8 md:block">
            {isMobile ? (
              <SearchBar showSubmitButton callBack={SearchBarCallBack} />
            ) : (
              <SearchBar showFilterText callBack={SearchBarCallBack} />
            )}
          </div>
          {hasSearchResults && (
            <>
              <div className="flex justify-between items-center">
                <h2 className=" text-2xl">{t('home.allExperiences')}</h2>
                <div className="flex md:hidden">
                  <FilterButton iconOnly></FilterButton>
                </div>
              </div>
              <p className="text-sm mb-6 mt-1">
                {t('searchBar.resultsFound', {
                  quantity: pagination?.totalRecords,
                })}
              </p>
            </>
          )}
          <ExperienceList
            experiences={experiences?.data || []}
            user={user}
            setFavoriteExperience={setFavoriteExperience}
          />
          {(currentPage < pagination?.totalPage) && hasSearchResults ? (
            <Button
              radius="full"
              className="!bg-black text-white w-36 mx-auto flex mt-16 md:mt-24"
              onClick={() => loadMoreExperiences(currentPage + 1)}
            >
              {t('destinationPage.viewMore')}
            </Button>
          ) : null}
          {!hasSearchResults && <NoResults />}
          <div className="mb-24 mt-8 md:mt-16">
            <div className="flex justify-between items-center">
              <h2 className={`text-2xl md:text-4xl my-6 md:my-4`}>
                {t('destinationPage.mayBeInterested')}
              </h2>
            </div>
            <GeneralCarousel
              data={cities || []}
              type={ExperienceType.DESTINATIONS}
            />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default SearchPage
