import React, { ReactElement, Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ModalBody,
  ModalContent,
  Modal as ModalNext,
  ScrollShadow,
} from '@nextui-org/react'
import { useIsMobile } from '@/utils/mobile'

interface ModalProps {
  children?: ReactElement
  isOpen: boolean
  onClose: () => void
}

const Modal = ({ children, isOpen, onClose }: ModalProps) => {
  const isMobile = useIsMobile()

  return (
    <ModalNext
      size={isMobile ? 'full' : 'xl'}
      className="md:!max-h-[90vh] md:h-auto"
      hideCloseButton
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        {() => (
          <>
            <ScrollShadow size={!isMobile ? 20 : 0} className="overscroll-none mx-auto">
              <ModalBody className=" scrollbar-hide">
                {children}
              </ModalBody>
            </ScrollShadow>
          </>
        )}
      </ModalContent>
    </ModalNext>
  )
}

export default Modal
