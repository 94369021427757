import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { setBookingDataAction } from '@/store/actions'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface ModalProps {
  children?: ReactElement
  open: boolean
  setVisible: any
  isDismissable?: boolean
  footer?: any,
  onClose?: any,
}

const ModalUi = ({
  children,
  open,
  setVisible,
  isDismissable = true,
  footer,
  onClose,
}: ModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { dispatch } = useGotuuriContext()

  const handleViewAgenda = () => {
    navigate('/agenda')
    dispatch(setBookingDataAction(null))
  }

  return (
    <Modal
      backdrop={'opaque'}
      placement={'center'}
      hideCloseButton={false}
      className="p-4"
      size="xl"
      isOpen={open}
      isDismissable={isDismissable}
      onClose={() => {
        setVisible(false)
        onClose()
      }}
    >
      <ModalContent>
        {() => (
          <>
            <ModalBody className="flex items-center">{children}</ModalBody>
            <ModalFooter className="flex items-center justify-around">
              {footer}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalUi
