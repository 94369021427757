import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {
  CustomLeftArrow,
  CustomRightArrow,
  Responsive,
} from '../CarouseElements'
import { useIsMobile } from '@/utils/mobile'
import { Link } from 'react-router-dom'
import { track } from '@/utils/analytics'
import { normalizeItem } from '@/utils/normalizeUrl'
import { ExperienceType } from '@/constants/generalCarousel'
import { FilterButton } from '@/components/FilterButton'
interface CategoriesCarouselProps {
  data: Record<string, never>[]
  autoPlaySpeed?: number
  items?: {
    xlarge?: number
    desktop?: number
    tablet?: number
    mobile?: number
  }
}
const CategoriesCarousel = ({
  data,
  autoPlaySpeed,
  items,
}: CategoriesCarouselProps) => {
  const customLeftClass =
    'h-10 w-10 absolute left-[6%] lg:left-[11%] 2xl:left-[21%] bg-white shadow-md p-3 rounded-full cursor-pointer'
  const customRightClass =
    'h-10 w-10 absolute right-[11%] lg:right-[15%] 2xl:right-[24%]  bg-white shadow-md p-3 rounded-full cursor-pointer'
  const isMobile = useIsMobile()

  return (
    <div className="flex flex-row mt-4 items-center">
      {Array.isArray(data) && !!data.length ? (
        <Carousel
          className=" maxContent text-black static py-4 h-auto w-screen md:w-4/5 mx-auto !overflow-scroll md:!overflow-hidden"
          responsive={Responsive(items)}
          infinite={isMobile ? false : true}
          focusOnSelect={false}
          customTransition={
            isMobile
              ? 'transform 0ms ease-in-out'
              : 'transform 200ms ease-in-out'
          }
          ssr={true}
          removeArrowOnDeviceType={['mobile']}
          autoPlay={autoPlaySpeed ? true : false}
          autoPlaySpeed={autoPlaySpeed ?? autoPlaySpeed}
          customLeftArrow={
            <CustomLeftArrow onClick={undefined} arrowClass={customLeftClass} />
          }
          customRightArrow={
            <CustomRightArrow
              onClick={undefined}
              arrowClass={customRightClass}
            />
          }
          centerMode={isMobile ? true : false}
        >
          {data.map((item, index) => {
            return (
              <Link
                key={index}
                to={normalizeItem({
                  ...item,
                  type: ExperienceType.CATEGORIES,
                })}
                tabIndex={-1}
                onClick={() =>
                  track('Category View', {
                    categoryName: item.name,
                    categoryId: item.id,
                    screen: 'Home',
                  })
                }
              >
                <div
                  key={index}
                  className={`flex flex-row h-auto md:h-14 w-auto rounded-2xl p-3 md:p-2 gotuuri-filter hover:text-gotuuriCyan !mx-2 md:mx-4 shadow-md items-center cursor-pointer`}
                >
                  <img
                    className="hidden md:block object-cover h-8"
                    src={item.icon}
                    width={32}
                    height={32}
                    alt={`${item.name} icon svg`}
                  />
                  <h2 className="text-sm md:text-sm  2xl:text-base text-center mx-auto select-none truncate">
                    {item.name}
                  </h2>
                </div>
              </Link>
            )
          })}
        </Carousel>
      ) : (
        <div className="w-full h-[76px] md:h-[88px]"></div>
      )}

      {!isMobile && (
        <FilterButton
          iconOnly
          customFunction={() =>track('Filter View', { screen: 'Home' })}
        />
      )}
    </div>
  )
}
export default CategoriesCarousel
