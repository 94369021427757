import { GotuuriState } from '@/models/gotuuri-state'
import { get } from '@/services/axios/axios'

import { setCitiesByCountryAction, setCountriesAction, setCountryAction } from 'store/actions'
import { ActionType } from 'store/actions/base-action'

export const useGetCountries = (dispatch: (action: ActionType) => void, state: GotuuriState) => {

  const getCountry = async ( countryId: string ) => {
    await get({
      path: `country/${countryId}`,
    }).then((data: unknown) => {
      console.log(data);
      dispatch(setCountryAction(data))
    })
  }

  const getCountries = async () => {
    await get({
      path: `countries/simple?limit=30`,
    }).then((data: any) => {
      dispatch(setCountriesAction(data))
    })
  }

  const getCitiesByCountry = async ({countryId, limit=30}) => {
    await get({
      path: `countries/${countryId}/destinations/simple?limit=${limit}`,
    }).then((data: any) => {
      dispatch(setCitiesByCountryAction(data.data))
    })
  }

  const deleteCitiesByCountry = () => {
    dispatch(setCitiesByCountryAction(null))
  }

  const deleteCountry = async ( countryId: string ) => {
    dispatch(setCountryAction(null))
  }



  return { getCountry, deleteCountry, getCountries, getCitiesByCountry, deleteCitiesByCountry }
}
