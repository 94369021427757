import { ERROR, SUCCESS, WARNING } from '@/constants/common'
import { useGotuuriContext } from 'hooks/useGotuuriContext'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const AlertToast = () => {
  const {
    state: { showAlert },
  } = useGotuuriContext()
  const { t } = useTranslation()

  const bgType = {
    [ERROR]: 'bg-rose-500',
    [SUCCESS]: 'bg-green-600',
    [WARNING]: 'bg-yellow-600',
  }

  const [showAlertPopup, setShowAlertPopup] = useState(false)

  useEffect(() => {
    if (showAlert && showAlert.show) {
      setShowAlertPopup(true)
      setTimeout(() => {
        setShowAlertPopup(false)
      }, 3000)
    }
  }, [showAlert])

  return (
    <>
      {showAlertPopup ? (
        <div className="w-full flex bottom-6  fixed justify-center z-[100] ease-in transition-transform-opacity duration-1000">
          <div
            className={`px-4 py-2 rounded-lg ${
              bgType[showAlert.type]
            } shadow-md`}
          >
            {' '}
            <span className="text-white font-medium text-base">
              {t(showAlert?.message)}
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AlertToast
