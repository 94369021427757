import { useTranslation } from "react-i18next"

const DestinationCard = ({ city }: any) => {
  const { t } = useTranslation()

  return (
    <div className="relative w-full h-44 md:h-60 lg:h-72 xl:h-80 rounded-2xl cursor-pointer bg-black">
      <img
        className="object-cover w-full h-full rounded-2xl opacity-90"
        src={city?.image}
        alt={city?.name}
      />
      <div className="absolute bottom-0 left-0 p-4 text-white">
        <h2 className="text-lg md:text-2xl lg:text-3xl select-none">
          {city?.name}
        </h2>
        <p className="hidden md:block md:text-xl select-none">
          {t('destinationPage.experienceQuantity', {quantity: city?.total_experiences})}
        </p>
        
      </div>
    </div>
  )
}

export default DestinationCard
