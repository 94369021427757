import {
  addHours,
  differenceInYears,
  format,
  isValid,
  Locale,
  parse,
  parseISO,
} from 'date-fns'
import { es, enUS } from 'date-fns/locale'
import { userPreferredLanguage } from './userPreferredLanguage'
import { capitalizer } from './capitalizer'

export const dateFnsLanguage = (): Locale => {
  const lang = userPreferredLanguage()
  const validLanguages = { es: es, en: enUS }
  return validLanguages[lang] || es
}

export const useFormatDate = (
  date: Date | string | Date,
  formater?: string
) => {
  date = new Date(date || Date())
  return capitalizer(
    format(date, formater || 'dd/MM/yy', {
      locale: dateFnsLanguage(),
    })
  )
}

export const getWeekDay = (date: Date) => {
  return format(date, 'EEEE dd', { locale: dateFnsLanguage() })
}

export const formatDatesArray = (dates) => {
  return dates.map((date) => {
    const constDate = parseISO(date)
    if (isValid(constDate)) {
      return addHours(constDate, 5)
    } else {
      return date
    }
  })
}

export const validateMinDate = (date) => {
  const parseDate = parse(date, 'yyyy-MM-dd', new Date())
  const today = new Date()
  const difference = differenceInYears(today, parseDate)
  return difference >= 18 ? true : false
}

export const validateMaxDate = (date) => {
  const parseDate = parse(date, 'yyyy-MM-dd', new Date())
  const today = new Date()
  const difference = differenceInYears(today, parseDate)
  return difference <= 90 ? true : false
}

export const isValidBirthDate = (date) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  if (regex.test(date)) {
    const parseDate = parse(date, 'yyyy-MM-dd', new Date())
    if (!isValid(parseDate)) {
      return false
    }
    return true
  }
  return false
}
