import { Spinner } from '@nextui-org/react'
// import gotuuriLogoGif from '@/assets/gifs/gotuuriLoading.gif'
interface LoadingProps{
    fullHeight?: boolean
}
const Loading = ({fullHeight = true}:LoadingProps) => {
  return (
    <div className={`${fullHeight? 'h-screen': 'h-full'}`}>
      <div className={`flex justify-center items-center ${fullHeight? 'h-screen': 'h-full'}`}>
        <Spinner />
        {/* <img src={gotuuriLogoGif} className=' w-64 h-64' alt="Gotuuri loading gif" /> */}
      </div>
    </div>
  )
}

export default Loading
