import { googleTrack, track } from './analytics'
import { GotuuriState } from '@/models/gotuuri-state'

interface StaticView {
  experiences: any
  state: GotuuriState
  page: number
}

export const googleEvents = {
  DestinationView: ({ experiences, state, page }: StaticView) => {
    googleTrack('DestinationView', {
      ecommerce: {
        destinationName: state.experiencesProperties?.name,
        destinationID: state.experiencesProperties?.id,
        page: page,
        referent: state?.user?.referent,
        experiences:
          !!experiences?.data.length &&
          experiences.data.map((experience) => {
            return {
              experienceId: experience.id,
              experienceName: experience.name,
              destinationID: experience.city.id,
              destinationName: experience.city.name,
              language: state.locale,
              loggedIn: state.isLoggedIn,
              price: experience.price_adults,
            }
          }),
      },
    })
  },
  CategoryView: ({ experiences, state, page }: StaticView) => {
    googleTrack('CategoryView', {
      ecommerce: {
        categoryName: state.experiencesProperties?.name,
        categoryId: state.experiencesProperties?.id,
        page: page,
        referent: state?.user?.referent,
        experiences:
          !!experiences.data.length &&
          experiences.data.map((experience) => {
            return {
              experienceId: experience.id,
              experienceName: experience.name,
              destinationID: experience.city.id,
              destinationName: experience.city.name,
              language: state.locale,
              loggedIn: state.isLoggedIn,
              price: experience.price_adults,
            }
          }),
      },
    })
  },
  HighlightView: ({ experiences, state, page }: StaticView) => {
    googleTrack('HighlightView', {
      ecommerce: {
        highlightName: state.experiencesProperties?.name,
        highlightID: state.experiencesProperties?.id,
        page: page,
        referent: state?.user?.referent,
        experiences:
          !!experiences.data.length &&
          experiences.data.map((experience) => {
            return {
              experienceId: experience.id,
              experienceName: experience.name,
              destinationID: experience.city.id,
              destinationName: experience.city.name,
              language: state.locale,
              loggedIn: state.isLoggedIn,
              price: experience.price_adults,
            }
          }),
      },
    })
  },
  CountryView: ({ experiences, state, page }: StaticView) => {
    googleTrack('HighlightView', {
      ecommerce: {
        highlightName: state.experiencesProperties?.name,
        highlightID: state.experiencesProperties?.id,
        page: page,
        referent: state?.user?.referent,
        experiences:
          !!experiences.data.length &&
          experiences.data.map((experience) => {
            return {
              experienceId: experience.id,
              experienceName: experience.name,
              destinationID: experience.city.id,
              destinationName: experience.city.name,
              language: state.locale,
              loggedIn: state.isLoggedIn,
              price: experience.price_adults,
            }
          }),
      },
    })
  },
}

export const CleverEvents = {
  CountryView: ({ name, id, screen }) => {
    track('Country View', {
      countryName: name,
      countryId: id,
      screen: screen,
    })
  },
  DestinationView: ({ name, id, screen }) => {
    track('Destination View', {
      destinationName: name,
      destinationId: id,
      screen: screen,
    })
  },
  HighlightView: ({ name, id, screen }) => {
    track('Highlight View', {
      highlightName: name,
      highlightId: id,
      screen: screen,
    })
  },
  CategoryView: ({ name, id, screen }) => {
    track('Category View', {
      categoryName: name,
      categoryId: id,
      screen: screen,
    })
  },
}
