import { FILTER } from '@/constants/form'
import { GotuuriState } from '@/models/gotuuri-state'
import { get, post } from '@/services/axios/axios'
import { ActionType } from '@/store/actions/base-action'
import { setFilterBody } from '@/utils/setFilterBody'
import {
  setExperiencesAction,
  setHasSearchResultsAction,
  setLoadingExperiencesAction,
  setPaginationAction,
} from 'store/actions'

interface FilterExperiences {
  page: number
  data: any
  newSearch?: boolean
  callBack?: (result) => void
}

export const useGetExperiences = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const getExperiences = async ({ page, newSearch, countryId }) => {
    if (newSearch) {
      dispatch(setExperiencesAction(null))
      dispatch(setPaginationAction(null))
    }
    dispatch(setLoadingExperiencesAction(newSearch))

    await get({
      path: `experiences/simple?${countryId ? `country=${countryId}&` : ''}page=${page}&limit=16`,
      headers: {
        'user-id': JSON.parse(localStorage.getItem('userData'))?.id,
        'Cache-Control': 'public, max-age=31536000, immutable',
      },
    }).then((result: any) => {
      dispatch(
        setPaginationAction({
          totalPage: result.pagination?.total_pages,
          currentPage: result.pagination?.current_page,
          totalRecords: result.pagination?.total_records,
          nextPage: result.pagination?.next_page,
          prevPage: result.pagination?.prev_page,
        })
      )

      dispatch(setHasSearchResultsAction(!!result?.pagination?.total_records))
      dispatch(
        setExperiencesAction({
          data: [
            ...(newSearch ? [] : state.experiences?.data ?? []),
            ...result.data,
          ],
        })
      )
      dispatch(setLoadingExperiencesAction(false))
    })
  }

  const getFilterExperiences = async ({
    page,
    data,
    newSearch = true,
  }: FilterExperiences) => {
    if (newSearch) {
      dispatch(setExperiencesAction(null))
      dispatch(setPaginationAction(null))
    }
    dispatch(setLoadingExperiencesAction(newSearch))
    let body = setFilterBody(data)
    try {
      const result: any = await post({
        path: `experiences/simple?page=${page}&limit=16`,
        data: body,
        headers: {
          'user-id': JSON.parse(localStorage.getItem('userData'))?.id,
        },
        'Cache-Control': 'public, max-age=31536000, immutable',
      })
      dispatch(
        setPaginationAction({
          totalPage: result.pagination?.total_pages,
          currentPage: result.pagination?.current_page,
          totalRecords: result.pagination?.total_records,
          nextPage: result.pagination?.next_page,
          prevPage: result.pagination?.prev_page,
        })
      )
      dispatch(
        setExperiencesAction({
          data: [
            ...(newSearch ? [] : state.experiences?.data ?? []),
            ...result.data,
          ],
        })
      )
      dispatch(setHasSearchResultsAction(!!result?.pagination?.total_records))
      dispatch(setLoadingExperiencesAction(false))
      return result
    } catch (e) {
      setExperiencesAction(null)
      dispatch(setLoadingExperiencesAction(false))
      dispatch(setHasSearchResultsAction(false))
      throw e
    }
  }

  return { getExperiences, getFilterExperiences }
}
