import { GotuuriState } from '@/models/gotuuri-state'
import { get } from '@/services/axios/axios'
import { setCitiesAction } from 'store/actions'
import { ActionType } from 'store/actions/base-action'

export const useGetCities = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
) => {
  const getCities = async (countryId = null) => {
    dispatch(setCitiesAction(null))
    await get({
      path: `all-cities${countryId ? `?country=${countryId}` : ''}`,
      headers: {
        'Cache-Control': 'public, max-age=31536000, immutable',
      },
    }).then((data: unknown) => {
      dispatch(setCitiesAction(data))
    })
  }

  return { getCities }
}
