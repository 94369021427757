import { get } from '@/services/axios/axios'

interface checkEmailProps {
  email: string
  handleSignIn?: (data) => void
  handleSignUp?: (data) => void
}

export const checkEmail = async ({
  email,
  handleSignIn = () => {},
  handleSignUp = () => {},
}: checkEmailProps) => {
  try {
    await get({ path: `check-email/${email}` })
    handleSignIn({ email: email })
    return true
  } catch (e) {
    handleSignUp({ email: email })
    return false
  }
}

export const recoverPassword = async (email) => {
  await get({ path: `recovery-password/${email}` })
}
