import { getWeekDay } from './formatDate'
import { userPreferredLanguage } from './userPreferredLanguage'

interface ScheduleFormatter {
  (
    companySchedule: any,
    t: any,
    uniqueDateString?: string,
    isDateRange?: boolean,
    uniqueDates?:Array<Date>
  ): string
}

const scheduleFormatter: ScheduleFormatter = (
  companySchedule,
  t,
  uniqueDateString = null,
  isDateRange = false,
  uniqueDates
) => {
  const lang = userPreferredLanguage() || 'es'
  const listFormat = new Intl.ListFormat(lang, {
    style: 'long',
    type: 'conjunction',
  })
  let daysString = ''

  const days = uniqueDates?.map((day) => getWeekDay(day)) || companySchedule?.days?.map((day) => t(`experience.${day}`))
  const formattedDays = listFormat.format(days)

  if (!!isDateRange) {
    daysString = `${uniqueDateString} ${formattedDays.toLowerCase()}`
  } else if (!isDateRange) {
    daysString = formattedDays
  }
  const hoursString = listFormat.format(companySchedule?.hours)
  if(!daysString && !hoursString) return null
  return `${daysString} ${hoursString ? t('experience.separator') : ''} ${hoursString}.`
}

export default scheduleFormatter
