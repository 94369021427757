import { GotuuriState } from '@/models/gotuuri-state'
import { get } from '@/services/axios/axios'

import { setExperiencesPropertiesAction } from 'store/actions'
import { ActionType } from 'store/actions/base-action'

export const useGetExperiencesProperties = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
) => {
  const getCityProperties = async (id) => {
    if (id == state?.experiencesProperties?.id) return
    dispatch(setExperiencesPropertiesAction(null))
    await get({
      path: `destinations/${id}/simple`,
    })
      .then((data: any) => {
        dispatch(
          setExperiencesPropertiesAction({
            name: data?.name,
            banner: data?.image,
            id: id,
          })
        )
      })
      .catch((e) => {})
  }
  const getCategoryProperties = async (id) => {
    if (id == state?.experiencesProperties?.id) return
    dispatch(setExperiencesPropertiesAction(null))
    await get({
      path: `categories/${id}/simple`,
    })
      .then((data: any) => {
        dispatch(
          setExperiencesPropertiesAction({
            name: data?.name,
            banner: data?.image,
            id: id,
          })
        )
      })
      .catch((e) => {})
  }
  const getHightlightProperties = async (id) => {
    if (id == state?.experiencesProperties?.id) return
    dispatch(setExperiencesPropertiesAction(null))
    await get({
      path: `highlights/${id}/simple`,
    })
      .then((data: any) => {
        dispatch(
          setExperiencesPropertiesAction({
            name: data?.name,
            banner: data?.image,
            id: id,
          })
        )
      })
      .catch((e) => {})
  }
  const getCountryProperties = async (id) => {
    if (id == state?.experiencesProperties?.id) return
    dispatch(setExperiencesPropertiesAction(null))
    await get({
      path: `country/${id}`,
    })
      .then((data: any) => {
        dispatch(
          setExperiencesPropertiesAction({
            name: data?.name,
            banner: data?.banner,
            id: id,
          })
        )
      })
      .catch((e) => {})
  }
  const setExperiencesProperties = (data) => {
    dispatch(setExperiencesPropertiesAction(data))
  }
  return {
    getCityProperties,
    getCategoryProperties,
    getHightlightProperties,
    getCountryProperties,
    setExperiencesProperties,
  }
}
