import React, { createContext, useContext } from 'react'
import { useGotuuriStore } from './useGotuuriStore'
import { GotuuriState } from '@/models/gotuuri-state'
import { useListenLocalStorage } from './useListenLocalStorage'
import { initializeClevertap } from '@/utils/analytics'
import { LOCAL_STORAGE_GIFTCARD } from '@/constants/localStorage'

const GotuuriContext = createContext<GotuuriState | unknown>(null)

export const ContextProvider = ({ children }: any) => {
  const { token, userData, bookingData, clevertap, locale, giftCardData } =
    useListenLocalStorage([
      'token',
      'userData',
      'localBookingData',
      'clevertap',
      'locale',
      LOCAL_STORAGE_GIFTCARD,
    ])
  const isLoggedIn = !!token && !!userData
  const data = userData && JSON.parse(String(userData) || '')
  const booking = bookingData && JSON.parse(String(bookingData) || '')
  const giftCard = giftCardData && JSON.parse(String(giftCardData) || '')

  const userLocale = locale || 'es'
  const value = useGotuuriStore(isLoggedIn, data, booking, userLocale, giftCard)

  if (clevertap !== 'true') {
    initializeClevertap()
  }

  return (
    <GotuuriContext.Provider value={value}>{children}</GotuuriContext.Provider>
  )
}

export const useGotuuriContext = (): any => {
  const context = useContext(GotuuriContext)
  return context
}
