
import leftIcon from 'assets/svgs/leftIcon.svg'
import rightIcon from 'assets/svgs/rightIcon.svg'
interface arrowProps {
    onClick?: any
    arrowClass?: string
}
export const CustomLeftArrow = ({ onClick, arrowClass }:arrowProps) => {
    const custom = arrowClass ? arrowClass : "h-12 w-12 absolute left-[8%] 2xl:left-[19%] bg-white shadow-md p-4 rounded-full cursor-pointer overflow-visible";
    return (
        <img onClick={() => onClick()} className={custom} src={leftIcon} alt="Left icon svg" />
    )
}
export const CustomRightArrow = ({ onClick, arrowClass }:arrowProps) => {
    const custom = arrowClass ? arrowClass : "h-12 w-12 absolute right-[8%] 2xl:right-[19%] bg-white shadow-md p-4 rounded-full cursor-pointer overflow-visible";
    return (
        <img onClick={() => onClick()} className={custom} src={rightIcon} alt="Right icon svg" />
    )
}

interface ResponsiveProps {
    xlarge?: number
    desktop?: number
    tablet?: number
    mobile?: number
}
export const Responsive = (items: ResponsiveProps = {}) => {
    const { xlarge=5, desktop = 3, tablet = 2, mobile = 1 } = items;
    return {
        //Lo dejé otra vez en 1900 pues no se porque en pantallas 1080p no se veía bien xlarge
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1901 },
            items: xlarge
        },
        desktop: {
            breakpoint: { max: 1900, min: 1025 },
            items: desktop
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: tablet
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: mobile
        }
    }
}