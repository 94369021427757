import React from 'react'
import GotuuriIcon from 'assets/icons/gotuuriBlueLogo.svg'
import { Button } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'

const SignUpSuccessModal = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center gap-4 my-12 ">
      <img src={GotuuriIcon} className="w-40 md:w-20 mb-4"></img>
      <h1 className="text-2xl mb-6">{t('signUpSuccess.welcome')}</h1>
      <p className="text-center mb-0">
        {t('signUpSuccess.description')}
      </p>
      <p className="hidden md:block text-center mb-2 ">
        {t('signUpSuccess.description1')}
      </p>
      <Button
        onClick={onClose}
        className="w-56 h-10 mx-auto text-base !bg-black text-white rounded-3xl hover:cursor-pointer px-12 mb-4 md:mt-0 mt-10"
      >
        {t('signUpSuccess.continue')}
      </Button>
      <p className="block md:hidden text-sm text-center">
        {t('signUpSuccess.description2')}
      </p>
    </div>
  )
}

export default SignUpSuccessModal
