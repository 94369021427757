import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useListenLocalStorage } from './useListenLocalStorage'
import '@/locales/i18n'

export const useListenTranslate = (): void => {
  const { i18n } = useTranslation()

  const localValues = useListenLocalStorage(['locale'])

  useEffect(() => {
    if (localValues && typeof localValues.locale === 'string' && localValues.locale !== 'null') {
      if (i18n.language !== localValues.locale){
        i18n.changeLanguage(localValues.locale as string)
        document.documentElement.lang = localValues.locale;
      }
    }
  }, [localValues.locale])
}
