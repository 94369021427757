import React from 'react'
import { useForm, SubmitHandler, Control } from 'react-hook-form'

import GotuuriIcon from 'assets/icons/gotuuriBlueLogo.svg'
import MailIcon from 'assets/icons/iconMail.svg'
import { Button, Input } from '@nextui-org/react'
import { FormErrorMessage } from '@/components/FormErrorMessage'
import { useTranslation } from 'react-i18next'
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { checkEmail } from '@/services/signInUpServices'
import { emailPatternRegex } from '@/constants/regex'
import InfoIcon from 'assets/svgs/Info.svg'

interface CheckEmailModalInput {
  email: string
}

interface CheckEmailModalProps {
  email?: string
  handleSignUp: (data: unknown) => void
  handleSignIn: (data: unknown) => void
  onClose: () => void
}

const CheckEmailModal = ({
  email,
  handleSignUp,
  handleSignIn,
  onClose,
}: CheckEmailModalProps) => {
  const {
    hooks: { handleSocialSignIn },
  } = useGotuuriContext()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: email,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  watch()

  const onSubmit: SubmitHandler<CheckEmailModalInput> = async ({ email }) => {
    await checkEmail({ email, handleSignIn, handleSignUp })
  }

  const onGoogleSigninSuccess = async (response) => {
    const credential = jwt_decode(response.credential)
    await handleSocialSignIn(credential, onClose)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-center flex-col my-auto h-full w-full -mt-6 md:-mt-0"
    >
      <div className="absolute top-2 md:top-3.5 md:left-7 ml-2 md:ml-0 left-3 text-2xl font-thin scale-x-125 cursor-pointer">
        <p onClick={onClose}>x</p>
      </div>
      <div className="flex flex-col items-center gap-4 m-10 md:mx-28 my-32 md:my-[40px] md:w-auto">
        <img src={GotuuriIcon} className="w-40 md:w-20 mb-8" alt='Gotuuri icon' />
        <h1 className="text-2xl mb-6">{t('checkEmailForm.signInUp')}</h1>
        <div>
          <Input
            isRequired
            {...register('email', {
              required: t('checkEmailForm.errors.required'),
              pattern: {
                message: t('checkEmailForm.errors.invalidEmail'),
                value: emailPatternRegex,
              },
            })}
            type="email"
            placeholder={t('checkEmailForm.email')}
            className={`outline-none !bg-white w-64 md:w-4/4 mb-1 !max-w-lg`}
            onValueChange={(value: string) => setValue('email', value)}
            startContent={<img src={MailIcon} alt="Mail icon" />}
            color={'default'}
            disableAnimation
            variant="underlined"
            value={getValues().email || ''}
          />
          <FormErrorMessage
            forField="email"
            control={control as Control<unknown>}
          />
        </div>
        <Button
          type="submit"
          isDisabled={!isValid}
          radius="full"
          className="!bg-black !w-56 text-base md:!w-4/5 mx-auto px-12 py-5 mb-4 text-white hover:cursor-pointer"
        >
          {t('checkEmailForm.continue')}
        </Button>
        <div className="flex flex-row items-center justify-center w-full mb-2">
          <div className="w-1/4 md:w-24 h-px bg-gray-300"></div>
          <p className="text-gray-500 w-fit text-xs mx-1 md:mx-2 md:px-2">
            {t('checkEmailForm.signInWith')}
          </p>
          <div className="w-1/4 md:w-24 h-px bg-gray-300"></div>
        </div>
        <div className="flex flex-row items-center justify-center w-full gap-6">
          <GoogleLogin
            onSuccess={onGoogleSigninSuccess}
            onError={() => {}}
            type="icon"
            prompt_parent_id="googleButton"
            key={'googleButton'}
          />
        </div>
      </div>
      <div className="bg-[#dcf2a7] rounded-full w-full p-4 mb-8 flex">
        <img src={InfoIcon} className="" alt="info icon" />
        <p
          className="[&>span]:font-bold text-sm text-center"
          dangerouslySetInnerHTML={{ __html: t('checkEmailForm.freeCredit') }}
        ></p>
      </div>
    </form>
  )
}

export default CheckEmailModal
