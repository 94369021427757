import React, { useEffect } from 'react'
import { Layout } from '@/components/Layout'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HighlightsCard } from '@/components/HighlightsCard'
import { normalizeItem } from '@/utils/normalizeUrl'
import { ExperienceType } from '@/constants/generalCarousel'
import { Loading } from '@/components/Loading'
import HighlightImage from '@/assets/images/highlights_bg.webp'

// const bgUrl =
//   'https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/background_web%2Fhighlights_bg.webp?alt=media&token=443d424b-a58d-46b3-95be-2c383f7e8ae7'

const HighlightPage = () => {
  const { t } = useTranslation()
  const {
    hooks: { getHighlights },
    state: { highlights },
  } = useGotuuriContext()

  useEffect(() => {
    getHighlights()
  }, [])

  return (
    <>
      {!!!highlights?.length ? (
        <>
          <div className="mb-16 rounded-3xl">
            <div
              className={`flex bg-cover bg-center h-80 md:h-96 text-white object-fill flex-col -mt-20 justify-center items-center relative rounded-b-4xl`}
              style={{ backgroundImage: `url(${HighlightImage})` }}
            >
              <div className="flex md:w-4/6 bottom-0 relative z-10">
                <Layout>
                  <div className=" text-white text-left md:text-center items-center my-8">
                    <h1 className="text-4xl md:text-6xl font-medium mt-14">
                      {t('home.allHighlights')}
                    </h1>
                  </div>
                </Layout>
              </div>
            </div>
          </div>
          <Loading />
        </>
      ) : (
        <>
          <div className="mb-16 rounded-3xl">
            <div
              className={`flex bg-cover bg-center h-80 md:h-96 text-white object-fill flex-col -mt-20 justify-center items-center relative rounded-b-4xl`}
              style={{ backgroundImage: `url(${HighlightImage})` }}
            >
              <div className="gradient-overlay rounded-b-4xl"></div>
              <div className="flex w-4/6 justify-center items-center bottom-0 relative z-10">
                <Layout>
                  <div className=" text-white text-center items-center my-8">
                    <h1 className="hidden md:block text-6xl font-medium mt-14">
                      {t('home.allHighlights')}
                    </h1>
                    <h1 className="block md:hidden text-4xl font-medium mt-14">
                      {t('home.highlights')}
                    </h1>
                  </div>
                </Layout>
              </div>
            </div>
          </div>
          <Layout
            title={t('home.highlights')}
            description={t('header.metaDescription')}
            image={HighlightImage}
          >
            <section className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 md:gap-x-6 md:gap-y-12 mb-24">
              {highlights.map((highlight, index) => {
                return (
                  <Link
                    key={index}
                    to={normalizeItem({
                      ...highlight,
                      type: ExperienceType.HIGHLIGHTS,
                    })}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <HighlightsCard highlight={highlight} />
                  </Link>
                )
              })}
            </section>
          </Layout>
        </>
      )}
    </>
  )
}

export default HighlightPage
