import {
  setCities,
  setCountry,
  setExperiences,
  setLoadingExperienceDetail,
  setNotFoundExperience,
  setLoadingExperiences,
  setHighlights,
  setCategories,
  setUserData,
  setPostUpdateProfile,
  setLoggedIn,
  setExperienceDetail,
  setScheduleByCompany,
  setPostAvailableHour,
  setPurchases,
  setShowAlert,
  setCategoryExperiences,
  setBookingData,
  setUniqueDates,
  setHasSearchResults,
  setLoadingPayment,
  setReferentCode,
  setGiftCardData,
  setLoadingRedeem,
  setLoadingPaymentGiftCard,
  setFavoriteExperiences,
  setLoadingProfileUpdate,
  setFilterData,
  setCountries,
  setCitiesByCountry,
  setExperiencesProperties,
  setPagination,
  setCarousels,
  setLoadingCarousels,
  setDisableSchedules,
  setStepUpRedirected,
  setAuthData,
  setIsNewUser,
  setIsProcessingPayment,

} from './base'
import { GotuuriState } from 'models/gotuuri-state'
import {
  SET_CATEGORIES,
  SET_CATEGORY_EXPERIENCES,
  SET_CITIES,
  SET_EXPERIENCES,
  SET_LOADING_EXPERIENCE_DETAIL,
  SET_LOADING_EXPERIENCES,
  SET_COMPANY_SCHEDULES,
  SET_AVAILABLE_HOURS,
  SET_HIGHLIGHTS,
  SET_LOGGED_IN,
  SET_USER_DATA,
  SET_UPDATE_PROFILE,
  SET_EXPERIENCE_DETAIL,
  SET_NOTFOUND_EXPERIENCE,
  SET_SHOW_ALERT,
  SET_PURCHASES,
  SET_BOOKING_DATA,
  SET_UNIQUE_DATES,
  SET_HAS_SEARCH_RESULTS,
  SET_LOADING_PAYMENT,
  SET_REFERENT_CODE,
  SET_GIFTCARD_DATA,
  SET_LOADING_REDEEM,
  SET_LOADING_PAYMENT_GIFTCARD,
  SET_FAVORITE_EXPERIENCES,
  SET_LOADING_PROFILE_UPDATE,
  SET_FILTER_DATA,
  SET_COUNTRY,
  SET_COUNTRIES,
  SET_CITIES_BY_COUNTRY,
  SET_EXPERIENCES_PROPERTIES,
  SET_PAGINATION,
  SET_CAROUSEL,
  SET_LOADING_CAROUSELS,
  SET_DISABLE_SCHEDULES,
  SET_STEP_UP_REDIRECTED,
  SET_AUTH_DATA,
  SET_IS_NEW_USER,
  SET_IS_PROCESSING_PAYMENT,
} from '../actions'
import { produce } from 'immer'

const reducers = {
  [SET_EXPERIENCES]: setExperiences,
  [SET_EXPERIENCES_PROPERTIES]: setExperiencesProperties,
  [SET_PAGINATION]: setPagination,
  [SET_LOADING_EXPERIENCES]: setLoadingExperiences,
  [SET_EXPERIENCE_DETAIL]: setExperienceDetail,
  [SET_NOTFOUND_EXPERIENCE]: setNotFoundExperience,
  [SET_LOADING_EXPERIENCE_DETAIL]: setLoadingExperienceDetail,
  [SET_COMPANY_SCHEDULES]: setScheduleByCompany,
  [SET_AVAILABLE_HOURS]: setPostAvailableHour,
  [SET_CITIES]: setCities,
  [SET_COUNTRY]: setCountry,
  [SET_COUNTRIES]: setCountries,
  [SET_CITIES_BY_COUNTRY]: setCitiesByCountry,
  [SET_HIGHLIGHTS]: setHighlights,
  [SET_CATEGORIES]: setCategories,
  [SET_CATEGORY_EXPERIENCES]: setCategoryExperiences,
  [SET_USER_DATA]: setUserData,
  [SET_UPDATE_PROFILE]: setPostUpdateProfile,
  [SET_LOGGED_IN]: setLoggedIn,
  [SET_LOADING_PAYMENT]: setLoadingPayment,
  [SET_SHOW_ALERT]: setShowAlert,
  [SET_PURCHASES]: setPurchases,
  [SET_BOOKING_DATA]: setBookingData,
  [SET_UNIQUE_DATES]: setUniqueDates,
  [SET_HAS_SEARCH_RESULTS] : setHasSearchResults,
  [SET_REFERENT_CODE] : setReferentCode,
  [SET_GIFTCARD_DATA]: setGiftCardData,
  [SET_LOADING_REDEEM]: setLoadingRedeem,
  [SET_LOADING_PAYMENT_GIFTCARD]: setLoadingPaymentGiftCard,
  [SET_FAVORITE_EXPERIENCES] : setFavoriteExperiences,
  [SET_LOADING_PROFILE_UPDATE] : setLoadingProfileUpdate,
  [SET_FILTER_DATA] : setFilterData,
  [SET_CAROUSEL]: setCarousels,
  [SET_LOADING_CAROUSELS]: setLoadingCarousels,
  [SET_DISABLE_SCHEDULES]: setDisableSchedules,
  [SET_STEP_UP_REDIRECTED] : setStepUpRedirected,
  [SET_AUTH_DATA] : setAuthData,
  [SET_IS_NEW_USER]: setIsNewUser,
  [SET_IS_PROCESSING_PAYMENT]: setIsProcessingPayment
}

const combineReducers = (reducers) => {
  return (state = {}, action) => {
    return produce(state, (draftState) => {
      return reducers[action.type](draftState, action)
    })
  }
}

export const rootReducer = combineReducers(reducers)
