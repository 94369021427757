import { Layout } from 'components/Layout'
import { useTranslation } from 'react-i18next'
import { SearchBar } from '../SearchBar'
import { MAIN_BANNER } from '@/constants/common'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { useNavigate } from 'react-router-dom'

interface HeaderBannerProps {}
const HeaderBanner = ({}: HeaderBannerProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const SearchBarCallBack = ({ filterUrl }) => {
    navigate(`/search?${filterUrl}`)
  }
  return (
    <div>
      <div
        className="hidden md:flex bg-cover bg-center h-102 text-white object-fill flex-col -mt-20 justify-center items-center relative"
        style={{ backgroundImage: `url(${MAIN_BANNER})` }}
      >
        <div className="gradient-overlay"></div>
        <div className="flex w-4/6 justify-center items-center bottom-0 relative z-10">
          <Layout>
            <div>
              <h1 className="text-5xl md:text-[54px] text-white text-left items-center my-8">
                {t('header.title')}
              </h1>
            </div>
            <SearchBar showSubmitButton callBack={SearchBarCallBack} eventScreen='Home' />
          </Layout>
        </div>
      </div>
      <div className="block md:hidden mt-8">
        <Layout>
          <SearchBar showFilterIcon callBack={SearchBarCallBack} eventScreen='Home' />
        </Layout>
      </div>
    </div>
  )
}

export default HeaderBanner
