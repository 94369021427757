import { Button, useDisclosure } from '@nextui-org/react'
import { FilterView } from '../FilterView'
import settingsImg from 'assets/svgs/settings.svg'
import { useTranslation } from 'react-i18next'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { initialForm } from '@/models/forms'

interface FilterButton {
  iconOnly?: boolean
  pathType?: string
  defaultValue?: keyof initialForm
  customFunction?: () => void
}
const FilterButton = ({
  iconOnly = false,
  pathType = '',
  defaultValue,
  customFunction,
}: FilterButton) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const { t } = useTranslation()
  const {
    state: { filterData },
  } = useGotuuriContext()

  const applyFilter = filterData?.filtersCount >= 1

  return (
    <>
      {iconOnly ? (
        <div className="relative">
          <p
            className={`${
              !!applyFilter
                ? 'bg-black absolute left-[32.5px] bottom-[35px]  rounded-full h-[18px] w-[18px] flex p-2 items-center justify-center !text-white text-[10px]'
                : 'hidden'
            } `}
          >
            {filterData?.filtersCount}
          </p>
          <Button
            onPress={() => {
              onOpen()
              if (customFunction) customFunction()
            }}
            isIconOnly
            radius="full"
            className="shadow my-auto"
            size="lg"
          >
            <img
              className={`w-6 h-6`}
              src={settingsImg}
              alt="Filter icon svg"
            />
          </Button>
        </div>
      ) : (
        <Button
          onPress={() => {
            onOpen()
            if (customFunction) customFunction()
          }}
          radius="full"
          className="flex shadow pl-2.5 pr-2 py-6 gap-3 text-lg"
        >
          <div className={`flex justify-between items-center gap-3 px-2`}>
            <img
              className={`first-letter:w-6 h-6`}
              src={settingsImg}
              alt="Filter icon svg"
            />
            <p className={`first-letter:w-6 h-6 mb-1`}>{t('filter.filters')}</p>
            <p
              className={`${
                !!applyFilter ? 'block' : 'hidden'
              } bg-black rounded-full h-6 w-6 flex items-center justify-center text-white text-xs`}
            >
              {filterData?.filtersCount}
            </p>
          </div>
        </Button>
      )}

      <FilterView
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        pathType={pathType}
        defaultValue={defaultValue}
      />
    </>
  )
}

export default FilterButton
