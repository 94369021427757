import { useTranslation } from "react-i18next"
const HighlightsCard = ({ highlight }) => {
  const { t } = useTranslation()
  return (
    <div className="relative w-full h-52 lg:h-72 rounded-2xl cursor-pointer bg-black">
      <img
        className="object-cover w-full h-full rounded-2xl opacity-90"
        src={highlight?.image}
        alt={highlight.name}
      />
      <div className="absolute bottom-0 left-0 p-4 text-white">
        <h2 className="text-3xl select-none">{highlight?.name}</h2>
        <p className="hidden md:block md:text-xl select-none">
          {t('destinationPage.experienceQuantity', {quantity: highlight?.total_experiences})}
        </p>
      </div>
    </div>
  )
}

export default HighlightsCard
