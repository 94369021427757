import { GotuuriState } from '@/models/gotuuri-state'
import { get, post } from '@/services/axios/axios'
import {
  setCarouselsAction,
  setExperienceDetailAction,
  setExperiencesAction,
  setLoadingExperiencesAction,
  setPaginationAction,
} from '@/store/actions'
import { ActionType } from '@/store/actions/base-action'
import { track } from '@/utils/analytics'
import { setFilterBody } from '@/utils/setFilterBody'

export const useHandleFavorites = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const setFavoriteExperience = async ({ experience, userId, type }) => {
    post({
      path: `experience/${experience?.id}/add-favorite`,
      data: null,
      headers: {
        'user-id': userId,
        'Cache-Control': 'public, max-age=31536000, immutable',
      },
    }).then((response) => {
      const types = {
        experience: () => {
          track('Favorite Select', {
            experienceName: experience?.name,
            experienceId: experience?.id,
            screen: 'Experience View',
            tuuriName: experience.companies[0].company_name,
            tuuriId: experience.companies[0].id,
          })
        },
        experiences: () => {
          track('Favorite Select', {
            experienceName: experience?.name,
            experienceId: experience?.id,
            screen: 'Experience Card',
          })
        },
        carousel: () => {
          track('Favorite Select', {
            experienceName: experience?.name,
            experienceId: experience?.id,
            screen: 'Carousel Experience Card',
          })
        },
      }
      if (!!response) {
        types[type] && types[type]()
      }
      const updateFavExperiences = (experiences) => {
        const newData = [...experiences]
        const index = newData.findIndex(({ id }) => id === experience.id)
        newData[index] = { ...newData[index], favorite: !!response }
        return newData
      }

      //actualizo el array de experiencias
      if (state?.experiences?.data) {
        const allExperiences = state?.experiences
        const newAllExps = updateFavExperiences(allExperiences?.data)
        dispatch(setExperiencesAction({ ...allExperiences, data: newAllExps }))
      }
      //actualizo los carousels
      if (state?.carousels?.length > 0) {
        const newCarrouselArray = state?.carousels.map((carrousel) => {
          const newCarrouselExp = updateFavExperiences(carrousel.experiences)
          return { ...carrousel, experiences: newCarrouselExp }
        })
        dispatch(setCarouselsAction(newCarrouselArray))
      }
      //actualizo la experiencia
      if (typeof state?.experience === 'object') {
        dispatch(
          setExperienceDetailAction({
            ...state?.experience,
            favorite: !!response,
          })
        )
      }
    })
  }

  const getFavoriteExperiences = async ({ page, newSearch, data }) => {
    if (newSearch) dispatch(setExperiencesAction(null))
    dispatch(setLoadingExperiencesAction(newSearch))

    let body = setFilterBody(data)

    await get({
      path: `all-experiences-favorites?page=${page}&limit=16&reduceData=true`,
      headers: {
        'user-id': JSON.parse(window.localStorage.getItem('userData'))?.id,
      },
    }).then((favoriteData: any) => {
      dispatch(
        setPaginationAction({
          totalPage: favoriteData?.totalPages,
          currentPage: favoriteData?.page,
          totalRecords: favoriteData.pagination?.total,
          nextPage: null,
          prevPage: null,
        })
      )
      const dataExperiences = [
        ...(newSearch ? [] : state.experiences?.data ?? []),
        ...favoriteData.data,
      ]

      track('Favorite View', {
        results: dataExperiences.length,
      })

      dispatch(
        setExperiencesAction({
          ...favoriteData,
          data: dataExperiences,
        })
      )
      dispatch(setLoadingExperiencesAction(false))
    })
  }

  return { setFavoriteExperience, getFavoriteExperiences }
}
