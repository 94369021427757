import { Pagination } from '@/models/pagination'
import { DisableSchedules } from '@/models/disableSchedules'
import { baseAction } from './base-action'

export const SET_EXPERIENCES = 'SET_EXPERIENCES'
export const SET_EXPERIENCES_PROPERTIES = 'SET_EXPERIENCES_PROPERTIES'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_LOADING_EXPERIENCES = 'SET_LOADING_EXPERIENCES'
export const SET_EXPERIENCE_DETAIL = 'SET_EXPERIENCE_DETAIL'
export const SET_LOADING_EXPERIENCE_DETAIL = 'SET_LOADING_EXPERIENCE_DETAIL'
export const SET_NOTFOUND_EXPERIENCE = 'SET_NOTFOUND_EXPERIENCE'
export const SET_COMPANY_SCHEDULES = 'SET_COMPANY_SCHEDULES'
export const SET_AVAILABLE_HOURS = 'SET_AVAILABLE_HOURS'
export const SET_CITIES = 'SET_CITIES'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_CITIES_BY_COUNTRY = 'SET_CITIES_BY_COUNTRY'
export const SET_HIGHLIGHTS = 'SET_HIGHLIGHTS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_UPDATE_PROFILE = 'SET_UPDATE_PROFILE'
export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const SET_SHOW_ALERT = 'SET_SHOW_ALERT'
export const SET_PURCHASES = 'SET_PURCHASES'
export const SET_CATEGORY_EXPERIENCES = 'SET_CATEGORY_EXPERIENCES'
export const SET_BOOKING_DATA = 'SET_BOOKING_DATA'
export const SET_UNIQUE_DATES = 'SET_UNIQUE_DATES'
export const SET_HAS_SEARCH_RESULTS = 'SET_HAS_SEARCH_RESULTS'
export const SET_LOADING_PAYMENT = 'SET_LOADING_PAYMENT'
export const SET_REFERENT_CODE= 'SET_REFERENT_CODE'
export const SET_GIFTCARD_DATA = 'SET_GIFTCARD_DATA'
export const SET_LOADING_REDEEM = 'SET_LOADING_REDEEM'
export const SET_LOADING_PAYMENT_GIFTCARD = 'SET_LOADING_PAYMENT_GIFTCARD'
export const SET_FAVORITE_EXPERIENCE= 'SET_FAVORITE_EXPERIENCE'
export const SET_FAVORITE_EXPERIENCES = 'SET_FAVORITE_EXPERIENCES'
export const SET_LOADING_PROFILE_UPDATE = 'SET_LOADING_PROFILE_UPDATE'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const SET_CAROUSEL = 'SET_CAROUSEL'
export const SET_LOADING_CAROUSELS = 'SET_LOADING_CAROUSELS'
export const SET_DISABLE_SCHEDULES = 'SET_DISABLE_SCHEDULES'
export const SET_STEP_UP_REDIRECTED = 'SET_STEP_UP_REDIRECTED'
export const SET_AUTH_DATA = 'SET_AUTH_DATA'
export const SET_IS_NEW_USER = 'SET_IS_NEW_USER'
export const SET_IS_PROCESSING_PAYMENT= 'SET_IS_PROCESSING_PAYMENT'

export const setExperiencesAction = (payload: unknown) => {
  return baseAction(SET_EXPERIENCES, payload)
}

export const setExperiencesPropertiesAction = (payload: unknown) => {
  return baseAction(SET_EXPERIENCES_PROPERTIES, payload)
}

export const setPaginationAction = (payload: Pagination) => {
  return baseAction(SET_PAGINATION, payload)
}

export const setLoadingExperiencesAction = (payload: unknown) => {
  return baseAction(SET_LOADING_EXPERIENCES, payload)
}

export const setExperienceDetailAction = (payload: unknown) => {
  return baseAction(SET_EXPERIENCE_DETAIL, payload)
}

export const setLoadingExperienceDetailAction = (payload: unknown) => {
  return baseAction(SET_LOADING_EXPERIENCE_DETAIL, payload)
}

export const setNotFoundExperienceAction = (payload: unknown) => {
  return baseAction(SET_NOTFOUND_EXPERIENCE, payload)
}

export const setScheduleByCompanyAction = (payload: unknown) => {
  return baseAction(SET_COMPANY_SCHEDULES, payload)
}

export const setPostAvailableHour = (payload: unknown) => {
  return baseAction(SET_AVAILABLE_HOURS, payload)
}

export const setCitiesAction = (payload: unknown) => {
  return baseAction(SET_CITIES, payload)
}

export const setCountryAction = (payload: unknown) => {
  return baseAction(SET_COUNTRY, payload)
}

export const setCountriesAction = (payload: unknown) => {
  return baseAction(SET_COUNTRIES, payload)
}

export const setCitiesByCountryAction = (payload: unknown) => {
  return baseAction(SET_CITIES_BY_COUNTRY, payload)
}


export const setHighlightsAction = (payload: unknown) => {
  return baseAction(SET_HIGHLIGHTS, payload)
}

export const setCategoriesAction = (payload: unknown) => {
  return baseAction(SET_CATEGORIES, payload)
}

export const setCategoryExperiencesAction = (payload: unknown) => {
  return baseAction(SET_CATEGORY_EXPERIENCES, payload)
}

export const setUserDataAction = (payload: unknown) => {
  return baseAction(SET_USER_DATA, payload)
}

export const setPostUpdateProfileAction = (payload: unknown) => {
  return baseAction(SET_UPDATE_PROFILE, payload)
}

export const setLoggedInAction = (payload: boolean) => {
  return baseAction(SET_LOGGED_IN, payload)
}

export const setLoadingPaymentAction = (payload: boolean) => {
  return baseAction(SET_LOADING_PAYMENT, payload)
}

export const setShowAlert = (payload: unknown) => {
  return baseAction(SET_SHOW_ALERT, payload)
}

export const setPurchasesAction = (payload: unknown) => {
  return baseAction(SET_PURCHASES, payload)
}

export const setBookingDataAction = (payload: unknown) => {
  return baseAction(SET_BOOKING_DATA, payload)
}

export const setUniqueDatesAction = (payload: unknown) => {
  return baseAction(SET_UNIQUE_DATES, payload)
}

export const setHasSearchResultsAction = (payload: unknown)=>{
  return baseAction(SET_HAS_SEARCH_RESULTS, payload)
}

export const setReferentCodeAction = (payload: unknown)=>{
  return baseAction(SET_REFERENT_CODE, payload)
}


export const setGiftCardDataAction = (payload: unknown) => {
  return baseAction(SET_GIFTCARD_DATA, payload)
}

export const setLoadingRedeemAction = (payload: boolean) => {
  return baseAction(SET_LOADING_REDEEM, payload)
}

export const setLoadingPaymentGiftCardAction = (payload: boolean) => {
  return baseAction(SET_LOADING_PAYMENT_GIFTCARD, payload)
}

export const setFavoriteExperienceAction = (payload: unknown)=>{
  return baseAction(SET_FAVORITE_EXPERIENCE, payload)
}

export const setFavoriteExperiencesAction = (payload: unknown)=>{
  return baseAction(SET_FAVORITE_EXPERIENCES, payload)
}

export const setLoadingProfileUpdate = (payload: boolean) => {
  return baseAction(SET_LOADING_PROFILE_UPDATE, payload)
}

export const setFilterDataAction = (payload: unknown) => {
  return baseAction(SET_FILTER_DATA, payload)
}

export const setCarouselsAction = (payload: unknown)=>{
  return baseAction(SET_CAROUSEL, payload)
}

export const setLoadingCarouselsAction = (payload: unknown)=>{
  return baseAction(SET_LOADING_CAROUSELS, payload)
}

export const setDisableSchedulesAction = (payload: DisableSchedules)=>{
  return baseAction(SET_DISABLE_SCHEDULES, payload)
}

export const setStepUpRedirectedAction = (payload: unknown) => {
  return baseAction(SET_STEP_UP_REDIRECTED, payload)
}
export const setAuthDataAction = (payload: unknown) => {
  return baseAction(SET_AUTH_DATA, payload)
}

export const setIsNewUserAction = (payload: unknown) => {
  return baseAction(SET_IS_NEW_USER, payload)
}

export const setIsProcessingPaymentAction = (payload: unknown) => {
  return baseAction(SET_IS_PROCESSING_PAYMENT, payload)
}