import { useEffect, useState } from 'react';

export const useListenLocalStorage = (
  arrayProps: string[]
): Record<string, unknown> => {
  const [localStorageValues, setLocalStorageValues] = useState({});

  useEffect(() => {
    const updateLocalStorageValues = () => {
      const newValues: Record<string, string | null> = {};
      let hasChanges = false;
      arrayProps.forEach((key) => {
        const newValue = window.localStorage.getItem(key);
        if (newValue !== localStorageValues[key]) {
          newValues[key] = newValue;
          hasChanges = true;
        }
      });
      if (hasChanges) {
        setLocalStorageValues((prevValues) => ({ ...prevValues, ...newValues }));
      }
    };

    // Initial update
    updateLocalStorageValues();

    const localStorageChangeEvents = () => {
      updateLocalStorageValues();
    };

    window.addEventListener('storage', localStorageChangeEvents);

    return () =>
      window.removeEventListener('storage', localStorageChangeEvents);
  }, [arrayProps, localStorageValues]);

  return localStorageValues;
};
