import { setFilterDataAction } from '@/store/actions'
import { ActionType } from '@/store/actions/base-action'

export const useHandleFilterData = (dispatch: (action: ActionType) => void) => {
  const setFilterData = (filterData) => {
    if( !!filterData ) {
      dispatch(setFilterDataAction({ ...filterData }))
    }else {
      dispatch(setFilterDataAction(null))
    }
  }

  return { setFilterData }
}
