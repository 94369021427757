import {
  COUPON_MAX_USES_BY_USER,
  COUPON_NOT_VALID_FOR_PRODUCT,
  COUPON_EXPIRED,
  COUPON_NOT_VALID_FOR_AMOUNT,
  ERROR,
} from '@/constants/common'
import { LOCAL_STORAGE_GIFTCARD } from '@/constants/localStorage'
import { post } from '@/services/axios/axios'
import {
  setBookingDataAction,
  setGiftCardDataAction,
  setShowAlert,
} from '@/store/actions'

export const useCouponValidation = (dispatch, state) => {
  const couponValidation = async (body) => {
    try {
      const { data }: any = await post({
        path: 'validate-coupon',
        data: body,
      })

      const coupon = {
        id: data.id,
        code: body.coupon_code,
        percent: data.percent,
        maxPriceByExperience: data.max_price_by_experience,
        maxDiscountApplied: data.max_discount_applied,
      }

      const couponValid = totalPriceCalculated(coupon)

      if (!!couponValid) return data.percent
    } catch (error) {
      if (error.data.error_reason === COUPON_MAX_USES_BY_USER) {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'resumeCard.couponHasMaxUsesPerUser',
          })
        )
      }
      if (error.data.error_reason === COUPON_EXPIRED) {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'resumeCard.couponExpired',
          })
        )
      }
      if (error.data.error_reason === COUPON_NOT_VALID_FOR_PRODUCT) {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'resumeCard.couponInvalid',
          })
        )
      }
      return false
    }
  }

  const giftCardCouponValidation = async (body) => {
    try {
      const { data }: any = await post({
        path: 'validate-coupon',
        data: body,
      })

      const coupon = {
        id: data.id,
        code: body.coupon_code,
        percent: data.percent,
      }

      totalPriceCalculatedGiftCard(coupon)

      return data.percent
    } catch (error) {
      return false
    }
  }

  const deleteCoupon = () => {
    const coupon = {
      code: null,
      percent: null,
    }

    totalPriceCalculated(coupon)
  }
  const deleteGiftCardCoupon = () => {
    const coupon = {
      code: null,
      percent: null,
    }
    totalPriceCalculatedGiftCard(coupon)
  }

  //se encarga de la logica de cambiar el precio total en base al coupon
  // funciona tanto para agregar un cupon como para quitar cupon
  const totalPriceCalculated = (coupon) => {
    const experience = state.bookingData.experience
    const participants = state.bookingData.totalParticipants

    const globalExperiencePrice = Object.keys(participants).reduce(
      (acc, key) => acc + experience[`price_${key}`] * participants[key],
      0
    ) + state?.bookingData?.additionalAmount ?? 0

    const useRangeDiscount = !!state?.bookingData?.rangeDiscount
    let rangeDiscount

    if (useRangeDiscount) {
      rangeDiscount =
          (globalExperiencePrice *
            (state.bookingData.rangeDiscount / 100));
    }

    let calculatedPercent

    let calculatedPrice = experience.is_package
      ? experience.price_package + state?.bookingData?.additionalAmount
      : globalExperiencePrice - (rangeDiscount ?? 0)

    const totalDiscountAux = (calculatedPrice * coupon.percent) / 100

    if (
      coupon.maxPriceByExperience > 0 &&
      calculatedPrice > coupon.maxPriceByExperience
    ) {
      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: 'resumeCard.couponNotElegiblePrice',
        })
      )
      return false
    }

    if ((coupon.maxDiscountApplied ?? 0) > 0) {
      if (totalDiscountAux > coupon.maxDiscountApplied) {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'resumeCard.couponHasMaxDiscount',
          })
        )

        calculatedPercent = (coupon.maxDiscountApplied * 100) / calculatedPrice

        coupon = {
          ...coupon,
          percent: calculatedPercent,
        }
      } else if (
        totalDiscountAux < coupon.maxDiscountApplied &&
        coupon.percentage != coupon.oldPercentage
      ) {
        coupon = { ...coupon, percentage: coupon.oldPercentage }
        totalPriceCalculated(coupon)
      }
    }

    if (coupon.percent > 0 && coupon.percent <= 100) {
      calculatedPrice *= 1 - coupon.percent / 100
    }

    const bookingData = {
      ...state.bookingData,
      percentDiscount: !!calculatedPercent ? calculatedPercent : null,
      amountDiscounted: state.bookingData.totalPrice - calculatedPrice,
      totalPrice: calculatedPrice,
      coupon: coupon,
    }

    window.localStorage.setItem('localBookingData', JSON.stringify(bookingData))
    dispatch(setBookingDataAction(bookingData))
    return true
  }

  const totalPriceCalculatedGiftCard = (coupon) => {
    let calculatedPrice = state.giftCardData.amount

    if (coupon.percent > 0 && coupon.percent <= 100) {
      calculatedPrice *= 1 - coupon.percent / 100
    }

    const giftCardData = {
      ...state.giftCardData,
      coupon: coupon,
      total: calculatedPrice,
    }

    window.localStorage.setItem(
      LOCAL_STORAGE_GIFTCARD,
      JSON.stringify(giftCardData)
    )

    dispatch(setGiftCardDataAction(giftCardData))
  }

  return {
    couponValidation,
    deleteCoupon,
    deleteGiftCardCoupon,
    giftCardCouponValidation,
  }
}
