export const getCookieValue = (cookieName) => {
  var name = cookieName + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var cookies = decodedCookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return null
}

export const isCookie = (cookieName: string, value: string) => {
  let cookie = document.cookie.includes(cookieName)
  return cookie
}

export const createCookie = (cookieName: string, cookieValue: string) => {
  const affiliateDuration = import.meta.env.VITE_AFFILIATE_DURATION || '{}'
  let objectValues = JSON.parse(affiliateDuration)
  let ndays = 30
  if (objectValues.hasOwnProperty(cookieValue)) {
    ndays = objectValues[cookieValue]
  } else if (objectValues.hasOwnProperty('default')) {
    ndays = objectValues.default
  }
  console.log(ndays)
  const date = new Date()
  date.setTime(date.getTime() + ndays * 24 * 60 * 60 * 1000) // 30 días
  const expires = '; expires=' + date.toUTCString()
  document.cookie = `${cookieName}=${cookieValue}${expires}; path=/`
}
