export const authBodyUtil = () => {
  const setAuthorizationBody = (
    purchaseData,
    state,
    referenceId,
    deviceInformation,
    cardType
  ) => {
    const authorizationBody = {
      orderInformation: {
        amountDetails: {
          currency: 'USD',
          totalAmount: !!state?.bookingData?.totalPrice
            ? state?.bookingData?.totalPrice +
              state?.bookingData?.totalParticipantQuantity
            : state?.giftCardData?.total,
        },
        billTo: {
          address1: !!state?.participants?.[0]?.address
            ? state?.bookingData?.participants[0]?.address
            : purchaseData?.state,
          address2: '',
          administrativeArea: purchaseData?.city,
          country: purchaseData?.country,
          locality: purchaseData?.city,
          firstName: purchaseData?.firstName,
          lastName: purchaseData?.lastName,
          phoneNumber: purchaseData?.phone,
          email: purchaseData?.email,
          postalCode: purchaseData?.postalCode,
        },
      },
      consumerAuthenticationInformation: {
        referenceId: referenceId,
      },
      paymentInformation: {
        card: {
          number: purchaseData?.cardNumber?.replaceAll('-', ''),
          expirationMonth: purchaseData?.expirationDate?.split('/')[0],
          expirationYear: purchaseData?.expirationDate?.split('/')[1],
          type:
            cardType === 'VISA'
              ? '001'
              : cardType === 'MASTERCARD'
                ? '002'
                : null,
        },
      },
      buyerInformation: {
        mobilePhone:
          purchaseData?.participants?.[0]?.phone ?? purchaseData?.phone,
      },
      deviceInformation,
    }

    return authorizationBody
  }

  const setValidateBody = (purchaseData, state) => {
    const validateBody = {
      clientReferenceInformation: {
        ...state?.authData?.data?.clientReferenceInformation,
      },
      orderInformation: {
        amountDetails: {
          currency: 'USD',
          totalAmount:
            state?.bookingData?.totalPrice +
            state?.bookingData?.additionalAmount +
            state?.bookingData?.totalParticipantQuantity,
        },
      },
      paymentInformation: {
        card: {
          number: purchaseData?.cardNumber?.replaceAll('-', ''),
          expirationMonth: purchaseData?.expirationDate?.split('/')[0],
          expirationYear: purchaseData?.expirationDate?.split('/')[1],
          type:
            purchaseData?.cardType === 'VISA'
              ? '001'
              : purchaseData?.cardType === 'MASTERCARD'
                ? '002'
                : null,
        },
      },
      consumerAuthenticationInformation: {
        ...state?.authData?.data?.consumerAuthenticationInformation,
        transactionMode: 'eCommerce',
      },
    }

    return validateBody
  }

  return { setAuthorizationBody, setValidateBody }
}
