import { useForm, SubmitHandler, Control, Controller } from 'react-hook-form'
import GotuuriIcon from 'assets/icons/gotuuriBlueLogo.svg'
import MailIcon from 'assets/icons/iconMail.svg'
import LockIcon from 'assets/svgs/lockIcon.svg'
import UserIcon from 'assets/svgs/userIcon.svg'
import BackIcon from 'assets/svgs/backIcon.svg'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { Button, Checkbox, Input, cn } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { FormErrorMessage } from '@/components/FormErrorMessage'
import PhoneInput from 'react-phone-input-2'
import { namePatternRegex } from '@/constants/regex'
import { setShowAlert } from '@/store/actions'
import { ERROR } from '@/constants/common'

interface SignUpModalInput {
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  country: string
  acceptTerms: boolean
}

interface SignUpModalProps {
  email: string
  handlecheckEmail: () => void
  handleSignUpSuccess: () => void
}

const SignUpModal = ({
  email,
  handlecheckEmail,
  handleSignUpSuccess,
}: SignUpModalProps) => {
  const {
    hooks: { handleSignUp },
    dispatch,
  } = useGotuuriContext()

  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: email,
      phone: '',
      password: '',
      country: '',
      acceptTerms: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  watch()

  const onSubmit: SubmitHandler<SignUpModalInput> = async (data) => {
    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: email,
      phone: data.phone.replaceAll(' ', ''),
      password: data.password,
      country: data.country,
      accept_terms: data.acceptTerms,
    }

    await handleSignUp({ body, handleSignUpSuccess })
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-center flex-row h-full w-full md:w-[500px] mb-6 -mt-6 md:mt-6"
    >
      <div className="absolute top-2 md:top-4 md:left-4 ml-2 md:-ml-0 left-0 text-2xl font-thin scale-x-125 cursor-pointer">
        <p onClick={() => handlecheckEmail()}>
          <img src={BackIcon} alt="go back icon" className="w-8 h-8 p-2 contrast-200" />
        </p>
      </div>
      <div className="flex flex-col items-center gap-4 m-10 mt-32 mb-6 md:mx-16 md:my-2 ">
        <img src={GotuuriIcon} className="w-40 md:w-20 mb-8" alt='gotuuri logo'/>
        <h1 className="text-2xl mb-6">{t('signUpForm.signUp')}</h1>
        <div className="flex flex-col items-center justify-center w-full md:w-full text-start">
          <Input
            isRequired
            {...register('firstName', {
              required: t('signUpForm.errors.required'),
              pattern: {
                message: t('signUpForm.errors.invalidFirstName'),
                value: namePatternRegex,
              },
            })}
            type="firstName"
            className={`outline-none !bg-white w-full md:w-full mb-1 !max-w-lg`}
            placeholder={t('signUpForm.firstName')}
            onValueChange={(value: string) => setValue('firstName', value)}
            startContent={<img src={UserIcon} alt="User icon" />}
            disableAnimation
            variant="underlined"
            value={getValues().firstName || ''}
          />
          <FormErrorMessage
            forField="firstName"
            control={control as Control<unknown>}
          />
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-full">
          <Input
            isRequired
            {...register('lastName', {
              required: t('signUpForm.errors.required'),
              pattern: {
                message: t('signUpForm.errors.invalidLastName'),
                value: namePatternRegex,
              },
            })}
            type="lastName"
            className={`outline-none !bg-white w-full md:w-full mb-1 !max-w-lg`}
            placeholder={t('signUpForm.lastName')}
            onValueChange={(value: string) => setValue('lastName', value)}
            startContent={<img src={UserIcon} alt="User icon" />}
            disableAnimation
            variant="underlined"
            value={getValues().lastName || ''}
          />
          <FormErrorMessage
            forField="lastName"
            control={control as Control<unknown>}
          />
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-full">
          <Input
            isReadOnly
            {...register('email')}
            type="email"
            className={`outline-none !bg-white w-full md:w-full mb-1 !max-w-lg`}
            placeholder={t('signUpForm.email')}
            startContent={<img src={MailIcon} alt="Mail icon" />}
            disableAnimation
            variant="underlined"
            value={email || ''}
          />
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-full">
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <PhoneInput
                preferredCountries={['pa', 'co', 'us', 'cr', 've']}
                masks={{ pa: '.... ....' }}
                {...register('phone', {
                  required: t('signUpForm.errors.required'),
                })}
                containerClass={`!flex !flex-row-reverse `}
                inputClass={`!w-full !h-10 !border-0 !border-b-2 ${
                  !!errors?.phone
                    ? '!border-red-400'
                    : 'focus:!border-black !border-gray-300'
                } !rounded-none !pl-2 text-black/90 !bg-white !ml-auto`}
                buttonClass={`!rounded-none !border-0 pl-1 w-18 flex !justify-center !relative !bg-white !border-b-2 ${
                  !!errors?.phone ? '!border-red-400' : '!border-gray-300'
                }`}
                dropdownClass={'!left-0 !top-10'}
                countryCodeEditable={false}
                country={'pa'}
                value={value}
                onBlur={onBlur}
                onChange={(value, country: any, e, formattedValue) => {
                  setValue('phone', formattedValue)
                  setValue('country', country.countryCode.toUpperCase())
                }}
                placeholder="xxxx-xxxx"
              />
            )}
          />
          <FormErrorMessage
            forField="phone"
            control={control as Control<unknown>}
          />
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-full">
          <Input
            isRequired
            {...register('password', {
              required: t('signUpForm.errors.required'),
              pattern: {
                message: t('signUpForm.errors.invalidPassword'),
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^<>\"'&;]{8,}$/,
              },
            })}
            type="password"
            className={`outline-none !bg-white w-full md:w-full mb-1 !max-w-lg`}
            placeholder={t('signUpForm.password')}
            onValueChange={(value: string) => setValue('password', value)}
            startContent={<img src={LockIcon} alt="Lock Icon" />}
            disableAnimation
            variant="underlined"
            value={getValues().password || ''}
          />
          <div className=" w-4/4 max-w-xs">
            <FormErrorMessage
              forField="password"
              control={control as Control<unknown>}
            />
          </div>
        </div>
        <div className="flex items-start gap-0 -mb-4">
          <Controller
            name="acceptTerms"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onValueChange={onChange}
                radius="none"
                isSelected={value}
                size="sm"
                className="-mt-1.5 md:-mt-1"
                classNames={{
                  wrapper: cn(
                    'group-data-[selected=true]:after:opacity-0',
                    'group-data-[selected=true]:before:bg-black'
                  ),
                }}
              />
            )}
          />
          <div
            className="text-center w-fit text-sm md:text-base mb-8 p-0 -mx-1 [&>a]:text-gotuuriCyan [&>a]:no-underline"
            dangerouslySetInnerHTML={{
              __html: t('signUpForm.disclosureFirstPart'),
            }}
          ></div>
        </div>
        <div
          className="flex justify-center w-full md:!max-w-lg"
          onClick={() => {
            dispatch(
              setShowAlert({
                show: true,
                type: ERROR,
                message: 'signUpForm.termsConsentError',
              })
            )
          }}
        >
          <Button
            className="w-full md:w-3/4 h-auto mx-auto !bg-black text-white font-thin rounded-3xl text-base hover:cursor-pointer px-12 py-2 mb-12 md:mb-4"
            type="submit"
            isDisabled={!isValid}
          >
            {t('signUpForm.signUpButton')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default SignUpModal
