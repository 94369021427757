import { get, post } from '@/services/axios/axios'
import {
  setDisableSchedulesAction,
  setExperienceDetailAction,
  setScheduleByCompanyAction,
  setUniqueDatesAction,
} from 'store/actions'
import { addHours, eachDayOfInterval, getHours, parseISO } from 'date-fns'
import scheduleFormatter from '@/utils/scheduleFormatter'
import { ActionType } from '@/store/actions/base-action'
import { GotuuriState } from '@/models/gotuuri-state'
import { formatDatesArray } from '@/utils/formatDate'

export const useHandleExperienceSchedules = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const postScheduleBycompany = async (body) => {
    const response = await post({
      path: 'get-schedules-by-experience-company',
      data: body,
    }).then((data: any) => {
      let schedule = data[0]
      const currentHour = getHours(new Date())
      const scheduleAnticipationTime = schedule?.anticipation_time ?? 0

      if (schedule) {
        schedule.anticipationDay =
          scheduleAnticipationTime + currentHour < 24
            ? 0
            : Math.max(Math.floor(scheduleAnticipationTime / 24), 1)
      }
      dispatch(setScheduleByCompanyAction(schedule))
    })
  }

  const getAvailableDates = async (id) => {
    await get({
      path: `get-available-dates/${id}`,
    }).then(({ data }: any) => {
      if (!!data?.dates?.length) {
        let dates: Array<Date> = formatDatesArray(data.dates)
        dates = dates.filter((date) => date >= new Date())
        if(!!state?.disableSchedules?.disabledDates){
          const disabledDatesSet = new Set(
            state.disableSchedules.disabledDates.map((date) => date.toString())
          )
          dates = dates.filter((date) => !disabledDatesSet.has(date.toString()))
        }
        
        dispatch(setUniqueDatesAction({ ...data, dates: dates }))
      } else if (data != null && data.type === 1) {
        const minDateFormatted = addHours(parseISO(data.start_date), 5)
        const maxDateFormatted = addHours(parseISO(data.end_date), 5)

        dispatch(
          setUniqueDatesAction({
            ...data,
            start_date: minDateFormatted,
            end_date: maxDateFormatted,
          })
        )
      } else {
        dispatch(setUniqueDatesAction(null))
      }
    })
  }

  const getDisableSchedules = async (id) => {
    await get({
      path: `get-locked-dates/${id}`,
    }).then((data: any) => {
      let disableDates: Date[] = []
      let disableHoursByDate: { [key: string]: string[] } = {}

      data.map((element) => {
        element.dates = formatDatesArray(element.dates)
        //cuando es para una sola fecha y está bloqueado todo el día
        if (element.type_date == 0 && element.lock_full_day) {
          disableDates.push(...element.dates)
        }
        //cuando es para una sola fecha y no está bloqueado todo el día
        if (element.type_date == 0 && !element.lock_full_day) {
          disableHoursByDate[element?.dates[0]] = element.time_slots
        }
        //cuando es un rango de fechas
        if (element.type_date == 1) {
          disableDates.push(
            ...eachDayOfInterval({
              start: element?.dates[0],
              end: element?.dates[1],
            })
          )
        }
        //cuando son fechas especificas
        if (element.type_date == 2) {
          disableDates.push(...element.dates)
        }
      })
      dispatch(
        setDisableSchedulesAction({
          disabledDates: disableDates,
          disableHours: disableHoursByDate,
        })
      )
    })
  }

  const changeExperienceSchedule = async (t) => {
    const uniqueDates: any = state.uniqueDates
    const companySchedule: any = state.companySchedule
    const experience: any = state?.experience ?? {}
    let schedule: string
    schedule = scheduleFormatter(
      companySchedule?.schedule,
      t,
      uniqueDates?.formattedDates,
      uniqueDates?.type == 1,
      uniqueDates?.dates
    )
    dispatch(
      setExperienceDetailAction({
        ...experience,
        details: experience?.details.map((detail, index) => {
          if (index === 1) {
            return { ...detail, value: schedule }
          }
          return detail
        }),
      })
    )
  }

  return {
    postScheduleBycompany,
    getAvailableDates,
    getDisableSchedules,
    changeExperienceSchedule,
  }
}
