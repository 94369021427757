import { HeaderBanner } from '@/components/HeaderBanner'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useCallback, useEffect, useState } from 'react'
import { BannerApps } from '@/components/BannerApps'
import { useTranslation } from 'react-i18next'
import { GeneralCarousel } from '@/components/Carousels/GeneralCarousel'
import { CategoriesCarousel } from '@/components/Carousels/CategoriesCarousel'
import { Layout } from '@/components/Layout'
import { ExperienceList } from '@/components/ExperienceList'
import { Button } from '@nextui-org/react'
import { setReferentCodeAction } from '@/store/actions'
import { track } from '@/utils/analytics'
import { Link, useLocation} from 'react-router-dom'
import { ExperienceType } from '@/constants/generalCarousel'
import { Loading } from '@/components/Loading'
import {
  getTopDestinationsLocaleString,
} from '@/utils/generalTitle.Util'
import { getCountryDestinationUrl } from '@/utils/normalizeUrl'
import { ExperienceCarousel } from '@/components/Carousels/ExperienceCarousel'

const MainPage = () => {
  const { t } = useTranslation()
  const {
    hooks: {
      getCities,
      getHighlights,
      getExperiences,
      setFavoriteExperience,
      setFilterData,
      getCarouselExperiences
    },
    state: {
      cities,
      country,
      highlights,
      categories,
      experiences,
      loadingExperiences,
      referentCode,
      user,
      pagination,
    },
    dispatch,
  } = useGotuuriContext()

  const { state: referent } = useLocation()
  const [currentPage, setCurrentPage] = useState(pagination?.currentPage ?? 1)

  useEffect(() => {
    if (referentCode) {
      localStorage.setItem('referentCode', referentCode)
      dispatch(setReferentCodeAction(null))
    }
    
    getCarouselExperiences({ type: 'home'})
    getCities()
    !!!highlights && getHighlights()
    setFilterData(null)
    getExperiences({
      page: currentPage,
      newSearch: true,
    })
    track('Home View', referent ? { url: referent } : null)
  }, [])

  const loadMoreExperiences = useCallback(
    (pageNumber) => {
      if (!!pagination?.currentPage && pageNumber <= pagination?.totalPage) {
        setCurrentPage(pageNumber)
        getExperiences({
          page: pageNumber,
          newSearch: false,
        })
      }
    },
    [experiences]
  )

  return (
    <>
      <HeaderBanner />
      <Layout
        title={t('header.metaTitle')}
        description={t('header.metaDescription')}
      >
        <CategoriesCarousel
          data={categories}
          items={{ xlarge: 4, desktop: 3, tablet: 2, mobile: 1.5 }}
        />
        <div className="flex justify-between items-center mb-6 mt-8">
          <h2 className={`text-2xl md:text-4xl `}>
            {t('home.highlights')}
          </h2>
          <Link to="/highlights">
            <Button className="!bg-black text-white rounded-full px-6 h-9">
              {t('home.viewAll')}
            </Button>
          </Link>
        </div>
        <GeneralCarousel
          data={highlights}
          itemsToShow={{ xlarge: 2, desktop: 2, tablet: 2, mobile: 1 }}
          autoPlaySpeed={4000}
          eventTrigger={(data) =>
            track('Highlight View', {
              highlightName: data.name,
              highlightId: data.id,
              screen: 'Home',
            })
          }
          type={ExperienceType.HIGHLIGHTS}
        />
        <div className="flex justify-between items-center mb-6 mt-12">
          <h2 className={`hidden md:block text-2xl md:text-4xl `}>
            {t(getTopDestinationsLocaleString(country?.iso_code))}
          </h2>
          <h2 className={`block md:hidden text-2xl md:text-4xl`}>
            {t('home.destinations')}
          </h2>
          <Link to={getCountryDestinationUrl(country)}>
            <Button className="!bg-black text-white rounded-full px-6 h-9">
              {t('home.viewAll')}
            </Button>
          </Link>
        </div>
        <GeneralCarousel
          data={cities}
          eventTrigger={(data) =>
            track('Destination View', {
              destinationName: data.name,
              destinationId: data.id,
              screen: 'Home',
            })
          }
          type={ExperienceType.DESTINATIONS}
        />
        <ExperienceCarousel textClass="text-xl md:text-4xl" />
        {loadingExperiences ? (
          <Loading />
        ) : (
          <ExperienceList
            experiences={experiences?.data || []}
            title={t('home.allExperiences')}
            user={user}
            setFavoriteExperience={setFavoriteExperience}
          />
        )}
        {currentPage < pagination?.totalPage ? (
          <Button
            radius="full"
            className="!bg-black text-white w-36 mx-auto flex mt-16 mb-10"
            onClick={() => loadMoreExperiences(currentPage + 1)}
          >
            {t('destinationPage.viewMore')}
          </Button>
        ) : null}
      </Layout>
    
      {/* <BannerApps /> */}
    </>
  )
}

export default MainPage
