import { useEffect } from 'react'
import { Layout } from '@/components/Layout'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'

import { DestinationCard } from '@/components/DestinationCard'
import { normalizeItem } from '@/utils/normalizeUrl'
import { ExperienceType } from '@/constants/generalCarousel'
import { Loading } from '@/components/Loading'
import DestinationImage from '@/assets/images/destinationBanner.webp'

// const bgUrl =
//   'https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/background_web%2FdestinationBanner.webp?alt=media&token=4b4a5228-3bb2-44d0-a4bd-25a4f78483e5'

const DestinationPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const {
    hooks: { getCities, getCountry, deleteCountry },
    state: { cities, country },
  } = useGotuuriContext()

  useEffect(() => {
    id ? getCountry(id) : deleteCountry()
    getCities(id)
  }, [])

  return (
    <>
      <div className="mb-16 rounded-3xl">
        <div
          className={`flex bg-cover bg-center h-80 md:h-96 text-white object-fill flex-col -mt-20 justify-center items-center relative rounded-b-4xl`}
          style={{ backgroundImage: `url(${DestinationImage})` }}
        >
          <div className="gradient-overlay rounded-b-4xl"></div>
          <div className="flex justify-center items-center bottom-0 relative z-10">
            <div className=" text-white text-left md:text-center items-center my-8 px-[5%] md:px-[0%]">
              <div className="flex justify-center">
                <div>
                  <h1
                    className={`text-4xl md:text-5xl lg:text-6xl font-medium  ${
                      country ? 'mt-2' : 'mt-14'
                    }`}
                  >
                    {t('home.topDestinations')}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Layout
        title={t('home.topDestinations')}
        description={t('header.metaDescription')}
        image={DestinationImage}
      >
        {Array.isArray(cities) && cities.length ? (
          <section className="grid grid-cols-2 md:grid-cols-3  gap-x-4 gap-y-8 md:gap-x-6 md:gap-y-12 mb-24">
            {cities.map((city, index) => {
              return (
                <Link
                  key={index}
                  to={normalizeItem({
                    ...city,
                    type: ExperienceType.DESTINATIONS,
                  })}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <DestinationCard city={city} />
                </Link>
              )
            })}
          </section>
        ) : (
          <Loading />
        )}
      </Layout>
    </>
  )
}

export default DestinationPage
