import React from 'react'
import { useForm, SubmitHandler, Control } from 'react-hook-form'
import GotuuriIcon from 'assets/icons/gotuuriBlueLogo.svg'
import LockIcon from 'assets/svgs/lockIcon.svg'
import BackIcon from 'assets/svgs/backIcon.svg'
import { useGotuuriContext } from 'hooks/useGotuuriContext'
import { Button, Input } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { FormErrorMessage } from '@/components/FormErrorMessage'
import { track } from '@/utils/analytics'
import { namePatternRegex } from '@/constants/regex'

interface SignInModalInput {
  password: string
}

interface SignInModalProps {
  email: string
  handlecheckEmail: () => void
  handleRecoverPassword: (data) => void
  onClose: () => void
}

const SignInModal = ({
  email,
  handlecheckEmail,
  handleRecoverPassword,
  onClose,
}: SignInModalProps) => {
  const {
    hooks: { handleSignIn, getExperiences },
  } = useGotuuriContext()

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      password: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  watch()

  const onSubmit: SubmitHandler<SignInModalInput> = async (data) => {
    const response = await handleSignIn({
      email,
      password: data.password,
      onClose,
    })

    !!response && await getExperiences({
      page: 1,
      newSearch: true,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-center flex-row h-full w-full -mt-6 md:-mt-10"
    >
      <div className="absolute top-2 md:top-4 md:left-4 ml-2 md:-ml-0 left-0 text-2xl font-thin scale-x-125 cursor-pointer">
        <p onClick={() => handlecheckEmail()}>
          <img src={BackIcon} alt="go back icon" className="w-8 h-8 p-2 contrast-200" />
        </p>
      </div>
      <div className="flex flex-col items-center gap-4 m-10 md:mx-28 my-32 md:my-[102px] md:w-auto">
        <img src={GotuuriIcon} className="w-40 md:w-20 mb-8" alt='Gotuuri icon' />
        <h1 className="text-2xl mb-6">{t('signInForm.signIn')}</h1>
        <div className="flex flex-col items-center justify-center">
          <Input
            isRequired
            {...register('password', {
              required: t('signInForm.errors.required'),
              pattern: {
                message: t('signInForm.errors.invalidPassword'),
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^<>\"'&;]{8,}$/,
              },
            })}
            type="password"
            className={`outline-none !bg-white w-64 md:w-4/4 mb-1 !max-w-lg`}
            placeholder={t('signInForm.password')}
            onValueChange={(value: string) => setValue('password', value)}
            startContent={<img src={LockIcon} alt="Lock " />}
            disableAnimation
            variant="underlined"
            value={getValues().password || ''}
          />
          <FormErrorMessage
            forField="password"
            control={control as Control<unknown>}
          />
        </div>
        <p
          className="font-thin underline mb-4 hover:cursor-pointer"
          onClick={() => {
            track('Email Login Forgot Password')
            handleRecoverPassword(email)
          }}
        >
          {t('signInForm.forgotPassword')}
        </p>
        <Button
          type="submit"
          isDisabled={!isValid}
          radius="full"
          className="!bg-black !w-56 text-base md:!w-4/5 mx-auto px-12 py-5 mb-4 text-white hover:cursor-pointer"
        >
          {t('signInForm.signInButton')}
        </Button>
      </div>
    </form>
  )
}

export default SignInModal
