import { get } from '@/services/axios/axios'
import { GotuuriState } from '@/models/gotuuri-state'
import { setCategoriesAction } from 'store/actions'
import { ActionType } from 'store/actions/base-action'

export const useGetCategories = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const getCategories = async () => {
    await get({
      path: 'all-categories',
    }).then((data: unknown) => {
      dispatch(setCategoriesAction(data))
    })
  }

  return { getCategories }
}
