import { FILTER } from '@/constants/form'

export const getFilterParams = (params) => {
  const obj: any = {}
  for (const [key, value] of params.entries()) {
    if (key.startsWith('utm')) {
      continue;
    }

    if (!obj.hasOwnProperty(key)) {
      obj[key] = []
    }

    if (!isNaN(value)) {
      obj[key].push(parseFloat(value))
    } else {
      obj[key].push(decodeURIComponent(value))
    }
  }
  for (const key in obj) {
    if (obj[key].length === 1) {
      obj[key] = obj[key][0]
    }
  }
  let totalCount = 0
  for (const [key, value] of params.entries()) {
    if (key.startsWith('utm')) {
      continue;
    }
    const decodedValue: any = decodeURIComponent(value)
    totalCount += !isNaN(decodedValue)
      ? 1
      : Array.isArray(decodedValue)
        ? decodedValue.length
        : 1
  }
  for (const key in obj) {
    if (!!!obj[key]) {
      totalCount--
      delete obj[key]
    }
  }
  function ensureArray(property) {
    if (obj[property] && !Array.isArray(obj[property])) {
      obj[property] = [obj[property]]
    }
  }
  ensureArray('cities')
  ensureArray('categories')
  ensureArray('properties')
  ensureArray('language')
  if (obj.rangePrice) {
    totalCount--
  }
  if (obj.duration) {
    totalCount--
  }
  if (obj.searchText) {
    totalCount--
  }
  if (
    obj?.rangePrice &&
    obj.rangePrice.length >= 2 &&
    obj.rangePrice[0] == FILTER.MINIMUM_PRICE &&
    obj.rangePrice[1] == FILTER.MAXIMUM_PRICE
  ) {
    totalCount--
  }
  if (
    obj?.duration &&
    obj.duration.length >= 2 &&
    obj.duration[0] == FILTER.MINIMUM_DURATION &&
    obj.duration[1] == FILTER.MAXIMUM_DURATION
  ) {
    totalCount--
  }

  return {
    urlParams: obj,
    filtersCount: totalCount,
  }
}
