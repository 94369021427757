import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { App } from "./App";

const rootElement = document.getElementById("root");
const isServerRendered = rootElement.getAttribute("data-server-rendered");

const renderApp = () => (
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
)

if (isServerRendered) {
  ReactDOM.hydrateRoot(rootElement, renderApp());
} else {
  ReactDOM.createRoot(rootElement).render(renderApp());
}
