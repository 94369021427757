import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import GotuuriIcon from 'assets/icons/gotuuriBlueLogo.svg'
import MailIcon from 'assets/icons/iconMail.svg'
import BackIcon from 'assets/svgs/backIcon.svg'
import { useTranslation } from 'react-i18next'
import { Button, Input } from '@nextui-org/react'
import { recoverPassword } from '@/services/signInUpServices'

interface RecoverPasswordModalInput {
  email: string
}

interface RecoverPasswordModalProps {
  email: string
  handleSignIn: ({ email }) => void
}

const RecoverPasswordModal = ({
  email,
  handleSignIn,
}: RecoverPasswordModalProps) => {
  const { t } = useTranslation()

  const { register, handleSubmit, getValues, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  watch()

  const onSubmit: SubmitHandler<RecoverPasswordModalInput> = async ({
    email,
  }: any) => {
    await recoverPassword(email)
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-center flex-row h-full w-full -mt-6 md:-mt-14"
    >
      <div className="absolute top-2 md:top-4 md:left-4 ml-2 md:-ml-0 left-0 text-2xl font-thin scale-x-125 cursor-pointer">
        <p onClick={() => handleSignIn({ email: getValues().email })}>
          <img src={BackIcon} alt="go back icon" className="w-8 h-8 p-2 contrast-200" />
        </p>
      </div>
      <div className="flex flex-col items-center gap-4 m-10 my-32 md:my-[118px] md:w-auto">
        <img src={GotuuriIcon} className="w-40 md:w-20 mb-8" alt='Gotuuri icon' />
        <h1 className="text-2xl -mb-2">
          {t('recoverPasswordForm.recoverPassword')}
        </h1>
        <p className="text-center mb-2">
          {t('recoverPasswordForm.description')}
        </p>
        <div className="flex flex-col items-center justify-center w-full">
          <Input
            isRequired
            isReadOnly
            {...register('email', { required: true })}
            type="email"
            className={`outline-none !bg-white w-64 md:w-4/4 mb-1 !max-w-lg`}
            placeholder={t('recoverPasswordForm.email')}
            startContent={<img src={MailIcon} alt="Mail icon" />}
            defaultValue={email || ''}
            disableAnimation
            variant="underlined"
          />
        </div>
        <div className="flex justify-center w-full md:!max-w-lg">
          <Button
            className="md:w-2/4 w-3/4 mx-auto !bg-black text-white font-thin rounded-3xl text-base hover:cursor-pointer px-12 py-2 mb-12 md:mb-4"
            type="submit"
            radius="full"
          >
            {t('recoverPasswordForm.recoverButton')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default RecoverPasswordModal
