import { GotuuriState, ShowAlert } from 'models/gotuuri-state'
import { ActionType } from 'store/actions/base-action'

export const setExperiences = (state: GotuuriState, action: ActionType) => {
  state.experiences = action.payload;
}

export const setExperiencesProperties = (state: GotuuriState, action: ActionType) => {
  state.experiencesProperties = action.payload;
}

export const setPagination = (state: GotuuriState, action: ActionType) => {
  state.pagination = action.payload;
}

export const setLoadingExperiences = (state: GotuuriState, action: ActionType) => {
  state.loadingExperiences = action.payload;
}

export const setNotFoundExperience = (state: GotuuriState, action: ActionType) => {
  state.notFoundExperience = action.payload;
}

export const setExperienceDetail = (state: GotuuriState, action: ActionType) => {
  state.experience = action.payload
}

export const setLoadingExperienceDetail = (state: GotuuriState, action: ActionType) => {
  state.loadingExperienceDetail = action.payload
}

export const setScheduleByCompany = (state: GotuuriState, action: ActionType) => {
  state.companySchedule = action.payload
}

export const setPostAvailableHour = (state: GotuuriState, action: ActionType) => {
  state.availableHours = action.payload
}

export const setCities = (state: GotuuriState, action: ActionType) => {
  state.cities = action.payload
}

export const setCountry = (state: GotuuriState, action: ActionType) => {
  state.country = action.payload
}

export const setCountries = (state: GotuuriState, action: ActionType) => {
  state.countries = action.payload
}

export const setCitiesByCountry = (state: GotuuriState, action: ActionType) => {
  state.citiesByCountry = action.payload
}

export const setHighlights = (state: GotuuriState, action: ActionType) => {
  state.highlights = action.payload
}

export const setCategories = (state: GotuuriState, action: ActionType) => {
  state.categories = action.payload
}

export const setCategoryExperiences = (state: GotuuriState, action: ActionType) => {
  state.categoryExperiences = action.payload
}

export const setUserData = (state: GotuuriState, action: ActionType) => {
  state.user = action.payload
}

export const setPostUpdateProfile = (state: GotuuriState, action: ActionType) =>{
  state.user = action.payload
}

export const setLoggedIn = (state: GotuuriState, action: ActionType) => {
  state.isLoggedIn = action.payload as boolean
}

export const setLoadingPayment = (state: GotuuriState, action: ActionType) => {
  state.loadingPayment = action.payload as boolean
}

export const setShowAlert = (state: GotuuriState, action: ActionType) => {
  state.showAlert = action.payload as ShowAlert
}

export const setPurchases = (state: GotuuriState, action: ActionType) => {
  state.purchases = action.payload
}

export const setBookingData = (state: GotuuriState, action: ActionType) => {
  state.bookingData = action.payload
}

export const setUniqueDates = (state: GotuuriState, action: ActionType) => {
  state.uniqueDates = action.payload
}

export const setHasSearchResults = (state: GotuuriState, action: ActionType) => {
  state.hasSearchResults = action.payload
}

export const setReferentCode = (state: GotuuriState, action: ActionType) => {
  state.referentCode = action.payload
}


export const setGiftCardData = (state: GotuuriState, action: ActionType) => {
  state.giftCardData = action.payload
}

export const setLoadingRedeem = (state: GotuuriState, action: ActionType) => {
  state.loadingRedeem = action.payload as boolean
}

export const setLoadingPaymentGiftCard = (state: GotuuriState, action: ActionType) => {
  state.loadingPaymentGiftCard = action.payload as boolean
}

export const setFavoriteExperiences = (state: GotuuriState, action: ActionType) => {
  state.favoriteExperiences = action.payload
}

export const setLoadingProfileUpdate = (state: GotuuriState, action: ActionType) => {
  state.loadingProfileUpdate = action.payload
}

export const setFilterData = (state: GotuuriState, action: ActionType) => {
  state.filterData = action.payload
}

export const setCarousels = (state: GotuuriState, action: ActionType) => {
  state.carousels = action.payload
}

export const setLoadingCarousels = (state: GotuuriState, action: ActionType) => {
  state.loadingCarousels = action.payload
}

export const setDisableSchedules = (state: GotuuriState, action: ActionType) => {
  state.disableSchedules = action.payload
}

export const setStepUpRedirected = (state: GotuuriState, action: ActionType) => {
  state.stepUpRedirected = action.payload
}

export const setAuthData = (state: GotuuriState, action: ActionType) => {
  state.authData = action.payload
}

export const setIsNewUser = (state: GotuuriState, action: ActionType) => {
  state.isNewUser = action.payload
}

export const setIsProcessingPayment = (state: GotuuriState, action: ActionType) => {
  state.isProccessingPayment = action.payload
}