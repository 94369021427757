import React, { ReactNode, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

interface LayoutProps {
  children: ReactNode
  title?: string
  description?: string
  image?: string
  type?: string
  name?: string
  keywords?: string
  keyword?: string
  metaDescription?: string
  className?: string
}

const Layout = ({
  title,
  description = '',
  image = '',
  type = 'article',
  name = 'Gotuuri',
  children,
  keywords,
  keyword,
  metaDescription = '',
  className,
}: LayoutProps) => {
  const { t } = useTranslation()
  description = description || t('header.metaDescription')

  const HelmetElements = () => (
    <Helmet>
      <title>{title || t('header.metaTitle')}</title>
    </Helmet>
  )
  return (
    <div className={`w-full layout-padding ${className}`}>
      <HelmetElements />
      {children}
    </div>
  )
}

export default Layout
