import { FILTER } from '@/constants/form'

export const setFilterBody = (data) => {
  let body: any = { ...data }
  if (data?.duration && data.duration.length > 0) {
    body.duration = [
      data.duration[0],
      data.duration[1] === FILTER.MAXIMUM_DURATION ? 'max' : data.duration[1],
    ]
  }
  if (data?.rangePrice && data.rangePrice.length > 0) {
    body.rangePrice = [
      data.rangePrice[0],
      data.rangePrice[1] === FILTER.MAXIMUM_PRICE ? 'max' : data.rangePrice[1],
    ]
  }

  function ensureArray(property) {
    if (body[property] && !Array.isArray(body[property])) {
      body[property] = [body[property]]
    }
  }
  ensureArray('cities')
  ensureArray('categories')
  ensureArray('properties')
  ensureArray('language')
  return body
}
