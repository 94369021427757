import { GotuuriState } from '@/models/gotuuri-state'
import { post } from '@/services/axios/axios'
import { ActionType } from '@/store/actions/base-action'
import { parseISO } from 'date-fns'
import { setPostAvailableHour } from 'store/actions'

export const usePostAvailableHour = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any =>  {
  const postAvailableHour = async (body) => {
    const response = await post({
      path: 'get-available-schedule',
      data: body,
    }).then((data: any) => {
      let hours = data 
      let bodyDate = String(parseISO(body?.date_booking))
      if(!!state?.disableSchedules?.disableHours[bodyDate]){
        hours = hours.filter(hour => !state.disableSchedules.disableHours[bodyDate].includes(hour.hour));
      }
      dispatch(setPostAvailableHour(hours))
    })
  }

  return { postAvailableHour }
}
