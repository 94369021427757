import {CleverTapWeb} from './clevertap'
import { userPreferredLanguage } from './userPreferredLanguage'
import { User } from '@/models/user'
const breakpoint = 640

export const track = (eventName: string, eventData?: Record<any, unknown>) => {
  const user = JSON.parse(window.localStorage.getItem('userData'))
  const source =
    window && window.innerWidth < breakpoint ? 'Web Mobile' : 'Web Desktop'
  if (!user?.isAdmin) {
    CleverTapWeb.event(eventName, {
      ...eventData,
      loggedIn: !!window.localStorage.getItem('token') || !!user,
      language: userPreferredLanguage(),
      source,
    })
  }
}

export const googleTrack = (
  eventName: string,
  eventData?: Record<any, unknown>
) => {
  const user = JSON.parse(window.localStorage.getItem('userData'))
  if (!user?.isAdmin) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    })
  }
}

export const identifyUser = (userData: User) => {
  const mappedData = {
    Name: userData.fullName || '',
    Identity: userData.id || '',
    Email: userData.email || '',
    Phone: userData.phone ?? '',
    id: userData.id || '',
    country: userData?.country || '',
    ...JSON.parse(window.localStorage.getItem('userLocation')),
  }
  if (!userData?.isAdmin) {
    CleverTapWeb.onUserLogin({
      Site: mappedData,
    })
  }
}

export const updateProfile = (userData: User) => {
  const mappedData = {
    Name: userData.fullName,
    Email: userData.email || '',
    Phone: userData.phone.replaceAll(' ', ''),
    country: userData?.country,
    ...JSON.parse(window.localStorage.getItem('userLocation')),
  }
  if (!userData?.isAdmin) {
    CleverTapWeb.profile({
      Site: mappedData,
    })
  }
}

export const initializeClevertap = () => {
  if (typeof window !== 'undefined') {
    CleverTapWeb.initialize(import.meta.env.VITE_CLEVERTAP_ID ?? '')
    window.localStorage.setItem('clevertap', 'true')
  }
}
