import MASTERCARD_ICON from '@/assets/svgs/masterCardColor.svg'
import VISA_ICON from '@/assets/svgs/visaColor.svg'
import GOTUURI_ICON from '@/assets/svgs/gotuuriColor.svg'
import PAYPAL_ICON from '@/assets/svgs/paypal.svg'

export const PAYMENT_METHODS = [
  { key: 'creditCard', icon1: MASTERCARD_ICON, icon2: VISA_ICON },
  { key: 'paypal', icon1: PAYPAL_ICON },
]

export const PAYMENT_METHODS_ALL = [
  { key: 'creditCard', icon1: MASTERCARD_ICON, icon2: VISA_ICON },
  { key: 'paypal', icon1: PAYPAL_ICON },
  { key: 'creditGotuuri', icon1: GOTUURI_ICON },
]

export const PAYMENT = "PAYMENT"
export const DETAILS = "DETAILS"

export const DEFAULT_PAYMENT_METHOD = 'creditCard'

export const CREDIT_PAYMENT_METHOD = 'creditCard'
export const CREDITGOTUURI_PAYMENT_METHOD = 'creditGotuuri'
export const PAYPAL_PAYMENT_METHOD = 'paypal'
export const CREDIT_CARD_TYPES = [
  {
    regex: /^4\d{12}(?:\d{3})?$/,
    cardType: 'VISA',
    cvvLength: 3,
    cardLength: 16,
    cvvMask: '###',
  },
  {
    regex: /^5[1-5]\d{14}$/,
    cardType: 'MASTERCARD',
    cvvLength: 3,
    cardLength: 16,
    cvvMask: '###',
  },
]
