import { setBookingDataAction } from '@/store/actions'
import { ActionType } from '@/store/actions/base-action'

interface setBookingDataProps {
    experience: {},
    bookingHour: string,
    totalQuantity: number
    bookingDate: any,
    totalParticipants: {},
    bookingPickupLocation?: {}
    bookingPickupReference?: string
    participants?: []
    totalPrice: number,
    apply_wallet: boolean,
    coupon: {
      code: string,
      percent: number,
    }
    updateProfile: boolean,
    percentDiscount?: number,
}

export const useHandleBooking = (  dispatch: (action: ActionType) => void) => {
  const setBookingData = async (bookingData:setBookingDataProps) => {
    dispatch(setBookingDataAction(null))
    window.localStorage.setItem('localBookingData', JSON.stringify(bookingData))
    dispatch(setBookingDataAction(bookingData))
  }
  return { setBookingData }
}
