import React from 'react'
import arrowBlack from 'assets/svgs/arrowBlack.svg'

const ArrowBlackImg = ({className = "rotate-90 w-5 h-5"}) => {
  return (
    <img
      className={className}
      src={arrowBlack}
      width={14}
      height={24}
      alt="Arrow black icon svg"
    />
  )
}

export default ArrowBlackImg
