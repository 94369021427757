export const ENGLISH = 'english'
export const SPANISH = 'spanish'
export const WHITE = 'white'
export const BLACK = 'black'
export const ERROR = 'error'
export const SUCCESS = 'Success'
export const FAILED = 'Failed'
export const WARNING = 'Warning'
export const ALT_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/background_web%2Fgrey-color.webp?alt=media&token=1438492a-8dce-4506-a780-ffedf423d523'
export const MAIN_BANNER = 'https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/background_web%2Fgotuuri_main_banner_bookea.webp?alt=media&token=8e0ff04c-0da6-4c60-9a33-8c9cefc33346'
export const FOOTER_BANNER = "https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/background_web%2Fgotuuri_main_footer_banner.webp?alt=media&token=dcb34e4b-e88f-4df8-bb26-fb3573644867"
export const GOTUURI_CYAN = '#00A9CE'
export const PANAMA_CODE = 'PA'
export const LANGUAGES = [
  {
    name: ENGLISH,
    code: 'en',
  },
  {
    name: SPANISH,
    code: 'es',
  },
]
export const LINK_APP = "https://bit.ly/42gtAFN"
export const LINK_QLTURECODE = "https://qlturecode.com/?utm_campaign=OrganicRef&utm_source=Gotuuri&utm_medium=Referral&utm_content=Referral"
export const USD = "USD"
export const ENABLE = 'Enable'
export const DISABLED = 'Disabled'
export const NAVBAR_ROUTES_COLORS = {
  "/highlights": WHITE,
  "/destinations": WHITE,
  "/categories": WHITE,
  "/country": WHITE
}
export const COUPON_MAX_USES_BY_USER = "COUPON_MAX_USES_BY_USER"
export const COUPON_NOT_VALID_FOR_PRODUCT = "COUPON_NOT_VALID_FOR_PRODUCT"
export const COUPON_EXPIRED = "COUPON_EXPIRED"
export const COUPON_NOT_VALID_FOR_AMOUNT = "COUPON_NOT_VALID_FOR_AMOUNT"