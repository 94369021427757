import { DISABLED, ENABLE } from '@/constants/common'
import { useCheckbox, Chip, VisuallyHidden, tv } from '@nextui-org/react'

const checkbox = tv({
  slots: {
    base: 'border-0 bg-white',
    content: 'text-default-500 ease-in-out duration-100',
  },
  variants: {
    isSelected: {
      true: {
        base: '!bg-white gotuuri-general-filter border-0 ',
        content: ' scale-110 transition ease-in-out duration-200',
      },
    },
  },
})

interface CustomCategoryCheckBox {
  icon: string
  value: string
  children: any
}

const CustomCategoryCheckBox = ({ icon, value, children }: CustomCategoryCheckBox) => {
  const {
    isSelected,
    getBaseProps,
    getLabelProps,
    getInputProps,
  } = useCheckbox({
    icon: icon,
    value: value,
  })

  const styles = checkbox({ isSelected })
  return (
    <>
      <label {...getBaseProps()}>
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>
        <Chip
          classNames={{
            base: styles.base(),
            content: styles.content(),
          }}
          color="primary"
          variant="faded"
          {...getLabelProps()}
        >
          <div className='flex flex-col items-center justify-start'>
            {icon && <img src={icon} alt={`${children} category icon`} className="w-7 h-7" />}
            <p className=' text-xs truncate '>{children ? children : isSelected ? ENABLE : DISABLED}</p>
          </div>
        </Chip>
      </label>
    </>
  )
}

export default CustomCategoryCheckBox
