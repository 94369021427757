import searchNotFound from '@/assets/svgs/searchNotFound.svg'
import { useTranslation } from 'react-i18next'
const NoResults = () => {
  const { t } = useTranslation()
  return (
    <div className="text-black">
      <p className="text-xl md:text-2xl">{t('search.noSearchResults')}</p>
      <ul className="list-disc ml-7 mt-4 md:mt-2 text-lg md:text-xl">
        <li>{t('search.translationWordsCorrect')}</li>
        <li> {t('search.translationUseGeneralKeywords')}</li>
      </ul>
      <div className="flex justify-center my-14 md:my-24">
        <img src={searchNotFound} alt="Search not found svg" />
      </div>
    </div>
  )
}

export default NoResults
