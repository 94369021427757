import { Slider } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'

interface Props {
  value: Array<number>
  onChange: (value: number) => void
  min: number
  max: number
  step: number
  formatValue: 'currency' | 'time'
}

const CustomSlider = ({
  value,
  onChange,
  min,
  max,
  step,
  formatValue,
}: Props) => {
  const { t } = useTranslation()
  const customToolTipFormat = (val) => {
    const format = {
      currency: `$${val}${val == max ? '+' : ''}`,
      time: val != max ? (t('filter.hour', { count: val }) as String) : t('filter.maximumHours', { hour: val }) as String,
    }
    return format[formatValue] || value
  }

  return (
    <Slider
      aria-label="Slider component"
      size="sm"
      renderThumb={({ index, ...props }) => (
        <div
          {...props}
          className="group relative top-1/2 h-16 flex w-14 items-center justify-center cursor-grab data-[dragging=true]:cursor-grabbing text-black font-medium "
        >
          {value[0] != value[1] ? (
            <>
              <span className="absolute top-1 text-sm">
                {index == 0 ? 'Min' : 'Max'}
              </span>
              <span className="absolute bottom-0 text-sm">
                {customToolTipFormat(value[index])}
              </span>
            </>
          ) : (
            <>
              <span className="absolute top-1 text-sm font-light">Max</span>
              <span className="absolute bottom-0 text-sm font-light">
                {customToolTipFormat(value[1])}
              </span>
            </>
          )}

          <span className="transition-transform bg-gotuuriCyan rounded-full w-5 h-5 block group-data-[dragging=true]:scale-85" />
        </div>
      )}
      classNames={{
        filler: 'bg-gotuuriCyan',
        label: 'text-2xl',
        labelWrapper: 'mb-4',
      }}
      value={value}
      step={step}
      minValue={min}
      maxValue={max}
      onChange={onChange}
    />
  )
}

export default CustomSlider
