import React from 'react'
import { Control, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FormErrorMessageProps {
  forField: string
  control: Control<unknown>
  defatulText?: string
}

const FormErrorMessage = ({
  forField,
  control,
  defatulText,
}: FormErrorMessageProps) => {
  const { t } = useTranslation()
  const { errors } = useFormState({ control })


  if (errors[forField]?.message) {
    return (
      <span className="text-red-400 text-base font-light -mt-1">
        {t(errors[forField]?.message)}
      </span>
    )
  }
  return !!defatulText ? (
    <span className="text-base text-red-400 font-light">{t(defatulText)}</span>
  ) : null
}

export default FormErrorMessage
