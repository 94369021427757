import { LOCAL_STORAGE_GIFTCARD } from '@/constants/localStorage'
import { setGiftCardDataAction } from '@/store/actions'
import { ActionType } from '@/store/actions/base-action'

interface setGiftCardDataProps {
  design: string
  amount: number,
  immediate_delivery: boolean,
  delivery_date_text: string,
  delivery_date: string | null,
  selected_delivery_date: boolean,
  delivery_details: {
    receiver_user: {
      name: string,
      email: string,
    },
    sender_user: {
      name: string,
      email: string,
    }
  },
  custom_message: string | null,
  coupon: any
}

export const useHandleGiftCard = (dispatch: (action: ActionType) => void) => {
  const setGiftCardData = async (giftCardData: setGiftCardDataProps) => {
    dispatch(setGiftCardDataAction(null))
    window.localStorage.setItem(
      LOCAL_STORAGE_GIFTCARD,
      JSON.stringify(giftCardData)
    )
    dispatch(setGiftCardDataAction(giftCardData))
  }
  return { setGiftCardData }
}
