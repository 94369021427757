import { Layout } from 'components/Layout'
import React, { memo, ReactNode } from 'react'

interface HeaderSubBannerProps {
  bannerImage: string
  loadingExperiences: boolean
  children: ReactNode
}

const HeaderSubBanner = memo(
  ({ bannerImage, loadingExperiences, children }: HeaderSubBannerProps) => {
    return (
      <div>
        <div
          className="flex bg-cover bg-center h-80 md:h-96 text-white object-fill flex-col -mt-20 justify-center items-center relative rounded-b-4xl"
          style={{
            backgroundImage: `url(${bannerImage})`,
          }}
        >
          <div
            className={`gradient-overlay blur-sm rounded-b-4xl ${
              loadingExperiences && 'animate-pulse'
            }`}
          ></div>
          <div className="flex md:w-4/6 justify-center items-center bottom-0 relative z-10">
            <Layout>{children}</Layout>
          </div>
        </div>
      </div>
    )
  }
)

export default HeaderSubBanner
